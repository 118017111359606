import React from 'react'
import { Link } from 'react-router-dom'

const SpecializeBox = (props) => {
    return (
        <>
            <div className="specialize-box-container" style={{ '--baseColor': props.baseColor }} data-aos="flip-left" data-aos-duration={1500} >
                <Link to={`/product/${props.productCode}`} className='d-flex gap-3 flex-column'>
                    <div className='image-box'>
                        <img src={props.icon} alt="" width={'250px'} height={'250px'} style={{objectFit: 'cover'}} />
                    </div>
                    <div className='text-box'>
                        <span className="text fw-bolder">{props.text}</span>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default SpecializeBox
