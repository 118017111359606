import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import BlogCard from '../Components/blog/BlogCard'
import GenralContext from '../context/GenralContext'

const AllBlogs = () => {

    const { allBlogs_client, isLoadingBlog_Client } = useContext(GenralContext)

    return (
        <>
            <section className="navFiller d-flex align-items-center justify-content-center flex-column mb-5">
                <div className='row mx-1 my-5 py-5'>
                    <span>&nbsp;</span>
                </div>
                <div className="row mx-1 my-5 px-5 py-4 justify-content-center flex-column">
                    <span className="fs-custom-big">All Blogs</span>
                    <div className='d-flex align-items-center justify-content-center gap-3 w-100 my-3'>
                        <Link to="/" className='text-decoration-none'><span className='fs-4 fw-bold text-white'>Home</span></Link>
                        <i className="fa-solid fa-angle-right text-white"></i>
                        <span className='fs-4 fw-bold text-white'>Blogs</span>
                    </div>
                </div>
            </section>

            <section className='my-5'>
                <div className="container mb-4">
                    <div className="d-flex justify-content-center">
                        <span className="text-theam" style={{ fontSize: '3rem' }}>
                            Explore Home Interior Designs with Concept Studio Bhopal
                        </span>
                    </div>
                </div>

                <div className="container-fluid px-md-5 px-2">
                    {isLoadingBlog_Client === true ?
                        <div className='w-100 d-flex justify-content-center align-items-center'>
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <div className="d-flex flex-wrap">
                            {allBlogs_client.map((ele) => {
                                return (
                                    <BlogCard data={ele} key={ele._id} />
                                )
                            })}
                        </div>
                    }
                </div>
            </section>
        </>
    )
}

export default AllBlogs