import React, { useContext, useEffect, useState } from 'react'
import TestimonialTR from './TestimonialTR';
import GenralContext from '../context/GenralContext';
import { useNavigate } from 'react-router-dom';
import BlogCard from './blog/BlogCard'
import JoditEditor from 'jodit-react';

const Admin = () => {

    const navigate = useNavigate();
    const GC = useContext(GenralContext)
    const { host, toDateString, projectsInformation, allComments, setAllComments } = GC

    const [testimonialData, setTestimonialData] = useState([])
    const getAllTestimonialData = async () => {
        await fetch(`${host}/testimonial/get/all`, {
            method: 'GET',
            headers: {
                "Content-Type": "Application/json",
                "auth-token": localStorage.getItem('auth-token')
            }
        }).then(async (result) => {
            let arr = await result.json();

            // sort data according to dates
            arr.data.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });

            setTestimonialData(arr.data);
        })
    }

    const [loading, setLoading] = useState(false)
    const [contactData, setContactData] = useState([])
    const getAllContactData = async () => {
        setLoading(true)
        await fetch(`${host}/contact/get`, {
            method: 'GET',
            headers: {
                "Content-Type": "Application/json",
                "auth-token": localStorage.getItem('auth-token')
            }
        }).then(async (result) => {
            let arr = await result.json();

            // sort data according to dates
            arr.data.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });

            setLoading(false)
            setContactData(arr.data);
        })
    }

    useEffect(() => {
        let token = localStorage.getItem('auth-token')
        if (!token) {
            navigate('/admin/login')
        } else {
            getAllTestimonialData();
            getAllContactData();
        }
        // eslint-disable-next-line
    }, [])

    const handleDeleteContactData = async (id) => {
        await fetch(`${host}/contact/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'Application/json',
                'auth-token': localStorage.getItem('auth-token')
            }
        }).then(async (data) => {
            let res = await data.json()
            if (res === 'Contact data deleted') {
                window.alert('Data Deleted')
                window.location.reload()
            } else {
                window.alert('Somthing went wrong with servers')
                console.log(res);
            }
        })
    }

    const handleLogout = () => {
        let flag = window.confirm('Are you sure to logout ?')

        if (flag === true) {
            localStorage.removeItem('auth-token')
            navigate('/')
        }
    }

    const [formData, setFormData] = useState({})
    const handleOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const [navTracker, setNavTracker] = useState({
        contact: true,
        testimonial: false,
        project: false,
        postBlog: false,
        blog: false,
        blogComments: false,
    })

    const setFalsy = () => {
        setNavTracker({
            contact: true,
            testimonial: false,
            project: false,
            postBlog: false,
            blog: false,
            blogComments: false,
        })
    }

    const handlePostProject = async (e) => {
        e.preventDefault()

        let temp = formData.url
        if (temp.length > 20) {
            temp = temp.substring(temp.length - 11)
        } else {
            window.alert("URL is too short")
            return;
        }

        try {
            await fetch(`${host}/project`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json',
                    'auth-token': localStorage.getItem('auth-token')
                },
                body: JSON.stringify({
                    title: formData.title,
                    url: formData.url
                })
            }).then(async (e) => {
                let res = await e.json()
                if (res._id) {
                    window.alert("Project Posted")
                    window.location.reload()
                } else {
                    window.alert("Somthing went with server!")
                }
            })

        } catch (error) {
            window.alert("Somthing went wrong!")
            console.error(error)
        }
    }

    const handleDeleteProjectData = async (id) => {
        try {
            await fetch(`${host}/project/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'Application/json',
                    'auth-token': localStorage.getItem('auth-token')
                }
            }).then(async (e) => {
                let res = await e.json()
                if (res === "Project Deleted") {
                    window.alert('Project Deleted')
                    window.location.reload()
                } else {
                    window.alert("Project Not Found")
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const [allBlogs, setAllBlogs] = useState([])
    useEffect(() => {
        (async () => {
            try {
                let raw = await fetch(`${host}/blogs/get-all-blogs`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'auth-token': localStorage.getItem('auth-token')
                    }
                })

                let jsonData = await raw.json();
                if (raw.status === 200) {
                    setAllBlogs(jsonData)
                } else {
                    console.log(jsonData)
                }

            } catch (error) {
                console.log(error);
                alert("Somthing went wrong!")
            }
        })();
    }, [host, setAllBlogs])

    const [blogContent, setBlogContent] = useState("")
    const [blogFormData, setBlogFormData] = useState({})
    const onBlogFormChange = (e) => {
        setBlogFormData({ ...blogFormData, [e.target.name]: e.target.value })
    }

    const handleResetButton = () => {
        setBlogContent("")
        setBlogFormData({})
    }

    const [isBlogFormProcess, setIsBlogFormProcess] = useState(false)
    const handlePostBlog = async (e) => {
        e.preventDefault()
        setIsBlogFormProcess(true)
        try {
            let raw = await fetch(`${host}/blogs/`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'auth-token': localStorage.getItem('auth-token')
                },
                body: JSON.stringify({
                    'text': blogFormData?.title ?? "",
                    'img': blogFormData?.img_url ?? "",
                    'details': blogContent,
                    'subDetails': blogFormData?.card_text ?? "",
                    'update_flag': blogFormData?.update_flag ?? false,
                    'blog_id': blogFormData?.blog_id ?? ''
                })
            })

            let jsonData = await raw.json()

            if (raw.status === 201) {
                setAllBlogs([...allBlogs, jsonData])
                alert("Blog posted. But, It's default visibility is hidden!")
                e.target.reset()
            } else if (raw.status === 200) {
                let temp = allBlogs.filter((ele) => ele._id !== blogFormData?.blog_id)
                temp.push(jsonData)
                setAllBlogs(temp)
                alert("Edited Successfully!")
            } else {
                console.log(jsonData);
                alert(jsonData)
            }

        } catch (error) {
            console.log(error)
            alert("Somthing went wrong!")
        } finally {
            setIsBlogFormProcess(false)
        }
    }

    const [Comments, setComments] = useState({})
    useEffect(() => {
        setComments(() => {
            return allComments.reduce((result, currentItem) => {
                const type = currentItem.ofBlog
                if (!result[type]) result[type] = []
                result[type].push(currentItem)
                return result;
            }, {});
        })

    }, [allComments])

    const handleDeleteComment = async (_id) => {
        setIsBlogFormProcess(true)
        try {
            let raw = await fetch(`${host}/blogs/comment`, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'auth-token': localStorage.getItem('auth-token')
                },
                body: JSON.stringify({
                    _id: _id
                })
            })

            let jsonData = await raw.json()

            if (raw.status === 200) {
                setAllComments(allComments.filter(ele => ele._id !== _id))
                alert('Comment Deleted')
            } else {
                alert(jsonData)
            }

        } catch (error) {
            console.log(error);
            alert("SERVER ERROR")
        } finally {
            setIsBlogFormProcess(false)
        }
    }

    return (
        <>
            <section id="navMarginStylish3" className='mb-5'></section>
            <div className="container my-5 py-4" style={{ fontSize: '160%' }}>
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button className={`nav-link ${navTracker.contact === true && 'active'}`} type="button" onClick={() => { setFalsy(); setNavTracker({ contact: true }) }}>Contact</button>
                        <button className={`nav-link ${navTracker.testimonial === true && 'active'}`} type="button" onClick={() => { setFalsy(); setNavTracker({ testimonial: true }) }}>Testimonial</button>
                        <button className={`nav-link ${navTracker.project === true && 'active'}`} type="button" onClick={() => { setFalsy(); setNavTracker({ project: true }) }}>Projects</button>
                        <button className={`nav-link ${navTracker.postBlog === true && 'active'}`} type="button" onClick={() => { setFalsy(); setNavTracker({ postBlog: true }) }}>Post Blogs</button>
                        <button className={`nav-link ${navTracker.blog === true && 'active'}`} type="button" onClick={() => { setFalsy(); setNavTracker({ blog: true }) }}>Blogs</button>
                        <button className={`nav-link ${navTracker.blogComments === true && 'active'}`} type="button" onClick={() => { setFalsy(); setNavTracker({ blogComments: true }) }}>Comments Manager</button>
                        <div className='ms-auto d-flex'>
                            <a href={`${host}/export/contact`} className="nav-link fw-normal bg-success text-white fs-4 ">
                                <i className="fa-solid fa-download"></i>
                            </a>
                            <button className="nav-link bg-danger text-white fs-4 fw-normal" onClick={handleLogout} type="button">
                                <i className="fa-solid fa-right-from-bracket"></i>
                            </button>
                        </div>
                    </div>
                </nav>

                {loading &&
                    <div className='d-flex align-items-center justify-content-center py-5 my-5'>
                        <div className="spinner-border text-dark fs-4" role="status" style={{ height: '4rem', width: '4rem' }}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                }

                {!loading &&
                    <>
                        {navTracker.contact === true &&
                            <div className={`tab-pane fade show active`}>
                                {!loading && contactData.length === 0 ?
                                    <div className='py-5 my-5 d-flex align-items-center justify-content-center'>
                                        <span className="fs-3 fw-bold py-3">
                                            No contact data found
                                        </span>
                                    </div>
                                    :
                                    <table className="w-100 mt-3 custom-table-desine">
                                        <thead>
                                            <tr className='border-bottom'>
                                                <th>Date</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>Message</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                contactData.map((ele) => {
                                                    return (
                                                        <tr key={ele._id}>
                                                            <td>{toDateString(ele.date)}</td>
                                                            <td>{ele.name}</td>
                                                            <td><span style={{ letterSpacing: '1px' }}>{ele.phone}</span></td>
                                                            <td><a href={`mailto:${ele.email}`}>{ele.email}</a></td>
                                                            <td>{ele.mess}</td>
                                                            <td>
                                                                <button type="button" className="d-flex justify-content-center align-items-start btn-reset w-100" onClick={() => { handleDeleteContactData(ele._id) }}>
                                                                    <i className="fa-solid fa-trash text-theam cursor-pointer"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        }

                        {navTracker.testimonial === true &&
                            <div className={`tab-pane fade show active`}>
                                {testimonialData.length === 0 ?
                                    <div className='py-5 my-5 d-flex align-items-center justify-content-center'>
                                        <span className="fs-3 fw-bold py-3">
                                            No testimonial data found
                                        </span>
                                    </div>
                                    :
                                    <table className="w-100 mt-3 custom-table-desine">
                                        <thead>
                                            <tr className='border-bottom'>
                                                <th>Date</th>
                                                <th>Name</th>
                                                <th>Designation</th>
                                                <th>Rating</th>
                                                <th>Message</th>
                                                <th>Email</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {testimonialData &&
                                                testimonialData.map((ele) => {
                                                    return (
                                                        <TestimonialTR id={ele._id} mess={ele.mess} rating={ele.rating} degi={ele.degi} email={ele.email} name={ele.name} date={ele.date} status={ele.status} key={ele._id} />
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        }

                        {navTracker.project === true &&
                            <div className={`tab-pane fade show active`}>
                                <div className="py-3">

                                    <form onSubmit={(e) => handlePostProject(e)} className='mt-3 mb-5 d-flex gap-3 custom-form-area flex-xxl-nowrap flex-lg-nowrap flex-md-wrap flex-wrap' id='contactForm'>
                                        <div className="form-group-custom d-flex flex-column col-xxl-5 col-lg-5 col-md-12 col-12">
                                            <input type="text" placeholder='Project Title*' name='title' onChange={handleOnChange} required={true} />
                                        </div>
                                        <div className="form-group-custom d-flex flex-column no-spinner col-xxl-5 col-lg-5 col-md-12 col-12">
                                            <input type="text" name="url" onChange={handleOnChange} required={true} placeholder='https://www.youtube.com/watch?v=...*' />
                                        </div>
                                        <div className="form-group-custom d-flex flex-column no-spinner w-100">
                                            <button type="submit" className="btn-reset bg-theam text-white py-3 px-3 rounded">Post</button>
                                        </div>
                                    </form>

                                    {projectsInformation && projectsInformation.length === 0 ?
                                        <div className='py-5 my-5 d-flex align-items-center justify-content-center'>
                                            <span className="fs-3 fw-bold py-3">
                                                No project data found
                                            </span>
                                        </div>
                                        :
                                        <table className="w-100 mt-3 custom-table-desine">
                                            <thead>
                                                <tr className='border-bottom'>
                                                    <th>Date</th>
                                                    <th>Title</th>
                                                    <th>URL</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {projectsInformation &&
                                                    projectsInformation.map((ele) => {
                                                        return (
                                                            <tr key={`conceptProjects_id=${ele._id}`}>
                                                                <td>{toDateString(ele.date)}</td>
                                                                <td>{ele.title}</td>
                                                                <td>{
                                                                    <a href={`https://youtu.be/${ele.url}`} target="_blank" rel="noopener noreferrer" className='text-decoration-underline'>https://youtu.be/{ele.url}</a>
                                                                }</td>
                                                                <td>
                                                                    <button type="button" className="d-flex justify-content-center align-items-start btn-reset w-100" onClick={() => { handleDeleteProjectData(ele._id) }}>
                                                                        <i className="fa-solid fa-trash text-theam cursor-pointer"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        }

                        {navTracker.postBlog === true &&
                            <div className='my-4'>
                                <form onSubmit={handlePostBlog} className='mt-3 mb-5 d-flex custom-form-area flex-wrap'>
                                    <div className="form-group-custom d-flex flex-column col-xxl-6 col-lg-6 col-md-12 col-12">
                                        <div className="pe-2 w-100">
                                            <input className='w-100' type="text" placeholder='Blog Title*' name='title' onChange={onBlogFormChange} required={true} disabled={isBlogFormProcess} defaultValue={blogFormData?.title ?? ""} />
                                        </div>
                                    </div>
                                    <div className="form-group-custom d-flex flex-column no-spinner col-xxl-6 col-lg-6 col-md-12 col-12">
                                        <div className="ps-2 w-100">
                                            <input className='w-100' type="text" name="img_url" onChange={onBlogFormChange} required={true} placeholder='Card Image URL - https://onedrive.live.com/embed?resid=*' disabled={isBlogFormProcess} defaultValue={blogFormData?.img_url ?? ""} />
                                        </div>
                                    </div>
                                    <div className="form-group-custom d-flex flex-column no-spinner col-12 pt-3">
                                        <textarea className='w-100' type="text" name="card_text" onChange={onBlogFormChange} required={true} placeholder='Card Text*' disabled={isBlogFormProcess} defaultValue={blogFormData?.card_text ?? ""} />
                                    </div>
                                    <div className="col-12 pt-3">
                                        <JoditEditor
                                            value={blogContent}
                                            tabIndex={1}
                                            onChange={newContent => { setBlogContent(newContent) }}
                                            disabled={isBlogFormProcess}
                                        />
                                    </div>
                                    <div className='my-5 d-flex flex-wrap gap-3 justify-content-center w-100'>
                                        {blogFormData?.update_flag === true ?
                                            <button type="submit" className={`bg-primary border-0 px-4 py-3 rounded-1 fs-2 text-white lh-1 text-uppercase ${isBlogFormProcess ? 'opacity-50' : ''}`} style={{ letterSpacing: '1px' }} disabled={isBlogFormProcess}>
                                                {isBlogFormProcess &&
                                                    <div class="spinner-border text-light me-2" role="status">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                }
                                                Update
                                            </button>
                                            :
                                            <button type="submit" className={`bg-theam border-0 px-4 py-3 rounded-1 fs-2 text-white lh-1 text-uppercase ${isBlogFormProcess ? 'opacity-50' : ''}`} style={{ letterSpacing: '1px' }} disabled={isBlogFormProcess}>
                                                {isBlogFormProcess &&
                                                    <div class="spinner-border text-light me-2" role="status">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                }
                                                Post
                                            </button>
                                        }
                                        <button type="reset" onClick={handleResetButton} className={`bg-theam border-0 px-4 py-3 rounded-1 fs-2 text-white lh-1 text-uppercase ${isBlogFormProcess ? 'opacity-50' : ''}`} style={{ letterSpacing: '1px' }}> reset </button>
                                    </div>
                                </form>

                            </div>
                        }

                        {navTracker.blog === true &&
                            <div className='my-4'>
                                <div className="d-flex flex-wrap">

                                    {allBlogs.map((ele) => {
                                        return (
                                            <BlogCard
                                                data={ele}
                                                admin_mode={true}
                                                nav={navTracker}
                                                setNav={setNavTracker}
                                                key={ele?._id}
                                                setBlogContent={setBlogContent}
                                                setBlogFormData={setBlogFormData}
                                                allBlogs={allBlogs}
                                                setAllBlogs={setAllBlogs}
                                                host={host}
                                            />
                                        )
                                    })}

                                </div>
                            </div>
                        }

                        {navTracker.blogComments === true &&
                            <div className='my-4'>
                                {
                                    Object.keys(Comments).map((blog) => {
                                        return (
                                            <div className='my-5'>
                                                <div>
                                                    <span className='line-height-1 mb-4 fs-2 text-capitalize' style={{ fontWeight: 400 }}>
                                                        <span className="fw-semibold">Blog:&nbsp;</span>
                                                        {allBlogs.find((ele) => { return ele._id === blog })?.text}
                                                    </span>
                                                </div>

                                                <table className="w-100 mt-3 custom-table-desine">
                                                    <thead>
                                                        <tr className='border-bottom'>
                                                            <th>Date</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Message</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Comments[blog].map((ele) => {
                                                            return (
                                                                <tr key={`conceptProjects_id=${ele._id}`}>
                                                                    <td>{toDateString(ele.createdAt)}</td>
                                                                    <td>{ele.name}</td>
                                                                    <td>{
                                                                        <a href={`mailto:${ele?.email}`}>{ele?.email}</a>
                                                                    }</td>
                                                                    <td>
                                                                        <span>
                                                                            {ele?.msg}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" className={`d-flex justify-content-center align-items-start btn-reset w-100 ${isBlogFormProcess === true ? 'opacity-50' : ''}`} onClick={() => { handleDeleteComment(ele._id) }} disabled={isBlogFormProcess}>
                                                                            <i className="fa-solid fa-trash text-theam cursor-pointer"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </>
                }

            </div>
        </>
    )
}

export default Admin
