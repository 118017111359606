import React, { useEffect, useRef, useState } from 'react'
import '../styles/navbar.scss'
import { Link, NavLink } from 'react-router-dom'
import logo from '../images/Logo-243x243.png'

const Navbar = () => {
    const [navToggler, setNavToggler] = useState(false);
    const handleNavToggler = () => {
        if (navToggler === true)
            setNavToggler(false)
        else
            setNavToggler(true)
    }

    const [delayToggler, setDelayToggler] = useState(false)
    const handleDelayNavToggler = () => {
        setDelayToggler(true)
        setTimeout(() => {
            setDelayToggler(false)
            handleNavToggler();
        }, 1000);
    }

    const [scrollAmt, setScrollAmt] = useState(0)
    window.addEventListener('scroll', () => {
        setScrollAmt(window.scrollY)
    });

    const logoImg = useRef("")
    useEffect(() => {
        let nav = document.querySelector('#mainNavbar-logo')
        let navWrapper = nav.querySelector('.nav-wrapper')

        if (scrollAmt > 50 && document.body.clientWidth > 576) {
            logoImg.current.style.width = '100px'
        } else {
            logoImg.current.style.width = '130px'
        }

        if (scrollAmt > 50) {
            nav.style.backdropFilter = 'brightness(0.8) blur(5px)'
            nav.style.transition = '0.3s'
            navWrapper.classList.add('col-11')
        } else {
            nav.style.backdropFilter = ''
            nav.style.transition = '0.3s'
            navWrapper.classList.remove('col-11')
        }

    }, [scrollAmt])

    return (
        <>
            <nav className='w-100' style={{ zIndex: 3 }} id='mainNavbar-logo'>
                <div className="gap-4 py-2 pb-2 px-5 nav-wrapper mx-auto">
                    <div className="d-flex gap-5 menu px-0">
                        <NavLink to='/'> Home </NavLink>
                        {/* <NavLink to='/about'> About </NavLink> */}
                        <NavLink to='/products'> Products </NavLink>
                        <NavLink to='/decmore'> Decmore </NavLink>
                    </div>

                    <div className="logo mx-xxl-4 mx-lg-4 mx-md-0 mx-0">
                        <Link to="/">
                            <img src={logo} alt="Concept Studio" ref={logoImg} />
                        </Link>
                    </div>

                    <div className="d-flex gap-5 menu px-0">
                        <NavLink to='/blogs'> Blogs </NavLink>
                        {/* <NavLink to="/studio">Studio</NavLink>
                        <NavLink to="/projects">Projects</NavLink> */}
                        <NavLink to='/testimonial'> Testimonial </NavLink>
                        <NavLink to='/contact'> Contact </NavLink>
                    </div>

                    <div className="nav-toggler">
                        <button type="button" className='btn-reset' onClick={handleNavToggler}>
                            <i className="fa-solid fa-bars-staggered"></i>
                        </button>
                    </div>
                </div>

                <div className={`${navToggler === true ? 'd-block text-white animate-width-0-100' : 'd-none'} ${delayToggler === true ? 'animate-width-100-0 d-block text-white' : ''}`} id='navToggleContainer' style={{ zIndex: 9 }}>
                    <div className="nav-toggler-inner w-100 d-flex justify-content-end p-5 pb-0 mt-3">
                        <button type="button" className='btn-reset px-3 py-2 text-white' onClick={handleDelayNavToggler}>
                            <i className="fa-solid fa-2x fa-xmark"></i>
                        </button>
                    </div>

                    <div className="navToggleContainer-inner flex-center-center flex-column gap-3 overflow-hidden">
                        <NavLink to='/' onClick={handleDelayNavToggler}> Home </NavLink>
                        <NavLink to='/about' onClick={handleDelayNavToggler}> About </NavLink>
                        <NavLink to='/products' onClick={handleDelayNavToggler}> Products </NavLink>
                        <NavLink to='/decmore' onClick={handleDelayNavToggler}> Decmore </NavLink>
                        {/* <NavLink to='/studio' onClick={handleDelayNavToggler}> Studio </NavLink> */}
                        <NavLink to='/projects' onClick={handleDelayNavToggler}> Projects </NavLink>
                        <NavLink to='/blogs' onClick={handleDelayNavToggler}> Blogs </NavLink>
                        <NavLink to='/testimonial' onClick={handleDelayNavToggler}> Testimonial </NavLink>
                        <NavLink to='/contact' onClick={handleDelayNavToggler}> Contact </NavLink>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar
