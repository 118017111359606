import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import GenralContext from '../context/GenralContext'

const Project = () => {

    const { projectsInformation } = useContext(GenralContext)

    return (
        <>
            <section className="navFiller d-flex align-items-center justify-content-center flex-column">
                <div className='row mx-1 my-5 py-5'>
                    <span>&nbsp;</span>
                </div>

                <div className="row mx-1 my-5 px-5 py-4 justify-content-center flex-column">
                    <span className="fs-custom-big">Our Projects</span>

                    <div className='d-flex align-items-center justify-content-center gap-3 w-100 my-3'>
                        <Link to="/" className='text-decoration-none'><span className='fs-4 fw-bold text-white'>Home</span></Link>
                        <i className="fa-solid fa-angle-right text-white"></i>
                        <span className='fs-4 fw-bold text-white'>Projects</span>
                    </div>
                </div>
            </section>

            <section id='project-section' className='container my-5 d-flex flex-column gap-5'>
                <div className="project-card d-flex justify-content-center flex-wrap gap-4">
                    {projectsInformation ?
                        <>
                            <div className="container d-flex justify-content-center">
                                <div className="row justify-content-center">
                                    <span className="width-fit text-theam text-center" style={{ fontSize: '3rem' }}>Our Successful Projects</span>
                                </div>
                            </div>
                            {projectsInformation && projectsInformation.map((ele) => {
                                return (
                                    <div key={`projectVideo_id=${ele._id}`}>
                                        <iframe iframe width="270" height="480" src={`https://www.youtube.com/embed/${ele.url}`} title={ele.url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                                    </div>
                                )
                            })}
                        </>
                        :
                        <>
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </>
                    }
                </div>
            </section>
        </>
    )
}

export default Project
