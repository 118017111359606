import React, { useContext, useEffect, useState } from 'react'
import GenralContext from '../context/GenralContext'
import { Link, useParams } from 'react-router-dom'

const ProductDetails = () => {

    const GC = useContext(GenralContext)
    const { specializeData } = GC
    const param = useParams();

    const [result, setResult] = useState(null)

    useEffect(() => {
        let obj = specializeData.find(o => o.productId === param.productCode);
        setResult(obj);
    }, [param.productCode, specializeData])

    return (
        <>
            {result ?
                <section className="product-details-main-container" style={{ '--color': result.baseColor }}>
                    <section className="navFiller d-flex align-items-center justify-content-center flex-column">
                        <div className='row mx-1 my-5 py-5'>
                            <span>&nbsp;</span>
                        </div>

                        <div className="row mx-1 my-5 px-5 py-4 align-items-center flex-column">
                            <span className="fs-custom-big">{result.productName}</span>
                            <div className='d-flex align-items-center justify-content-center gap-3 w-100 my-3'>
                                <Link to="/" className='text-decoration-none'>
                                    <span className='fs-4 fw-bold text-white'>Home</span>
                                </Link>
                                <i className="fa-solid fa-angle-right text-white"></i>
                                <Link to="/products" className='text-decoration-none'>
                                    <span className='fs-4 fw-bold text-white'>Products</span>
                                </Link>
                                <i className="fa-solid fa-angle-right text-white"></i>
                                <span className='fs-4 fw-bold text-white'>{result.productName}</span>
                            </div>
                        </div>
                    </section>

                    <div className='container' data-aos="fade-up" data-aos-duration="1000">

                        <div className='my-5' data-aos="fade-down" data-aos-duration="1000">
                            <div className="row w-100 justify-content-center">
                                <span className="width-fit text-theam text-center" style={{ fontSize: '3rem' }}>{result.subHeading}</span>
                            </div>
                        </div>

                        <div className="row px-4">
                            <div className={`description-area`}>
                                <ul className='px-0 mx-0'>
                                    <li className='list-unstyled'>
                                        <p className='fs-3 text-justify' style={{ fontWeight: 450 }}>{result.para1}</p>
                                    </li>
                                    <li className='list-unstyled'>
                                        <p className='fs-3 text-justify' style={{ fontWeight: 450 }}>{result.para2}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className={`my-5 py-3 product-details-keyPoint d-flex flex-xxl-nowrap flex-lg-nowrap flex-md-wrap flex-wrap justify-content-center xxl-gap-0 lg-gap-0 md-gap-5 gap-5`}>

                            <div className="d-flex flex-column">
                                <div className="image-area mx-auto border-bottom-0">
                                    {/* <img src={result.productImg} alt="" /> */}
                                    {/* data-bs-ride="carousel" */}
                                    <div id="product-image" className="carousel slide h-100">
                                        <div className="carousel-inner h-100">
                                            <div className="carousel-item h-100 active">
                                                <img src={result.productImg} className="d-block h-100" alt="..." />
                                            </div>

                                            {result.stackImg &&
                                                result.stackImg.map((ele, idx) => {
                                                    return (
                                                        <div className="carousel-item h-100" key={result.productId + '-stackImg-' + idx}>
                                                            <img src={ele} className="d-block h-100" alt="..." />
                                                        </div>
                                                    )
                                                })
                                            }

                                            <div className="carousel-item h-100">
                                                <img src={result.productImg} className="d-block h-100" alt="..." />
                                            </div>
                                            <div className="carousel-item h-100">
                                                <img src={result.productImg} className="d-block h-100" alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 p-2 d-flex gap-2 justify-content-center" style={{ border: '3px solid #002b63' }}>
                                    <button type="button" className='btn-reset bg-theam text-white fs-4 py-2 w-100' data-bs-target="#product-image" data-bs-slide="prev">
                                        PREV
                                    </button>
                                    <button type="button" className='btn-reset bg-theam text-white fs-4 py-2 w-100' data-bs-target="#product-image" data-bs-slide="next">
                                        NEXT
                                    </button>
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-3 w-100">
                                <div className="row mx-2 border-bottom">
                                    <span className="ff-quicksand px-0 text-theam" style={{ fontSize: '25px', fontWeight: 550 }}>&nbsp;{result.productName}&nbsp;</span>
                                </div>
                                <div>
                                    <ul className='px-4'>
                                        {result.details && result.details.map((ele, idx) => {
                                            return (
                                                <li className='list-unstyled' key={`product-point-${idx + 1}`}>
                                                    <span className='fs-3 text-theam' style={{ fontWeight: 500 }}>{ele.heading}&nbsp;</span>
                                                    <p className='fs-3 text-justify' style={{ fontWeight: 450 }}>{ele.para}</p>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>


                        {result.application &&
                            <section id='conceptStudioOffersSection' className='mb-5 pb-5' data-aos="fade-up" data-aos-duration="1500">
                                <div className="my-4 px-3 d-flex d-flex flex-column align-items-center" data-aos="fade-up" data-aos-duration="2000">
                                    <span className='ff-quicksand mb-4 text-center text-theam' style={{ fontSize: '25px', fontWeight: 450 }}>&nbsp;Let's See Application of {result.productName}&nbsp;</span>
                                    <div className='d-flex gap-4 flex-wrap justify-content-center'>

                                        {result.application.map((ele, idx) => {
                                            return (
                                                <div className="decmore-offer-card" data-aos="flip-right" data-aos-duration="2000" key={`product-appllication-${idx + 1}`}>
                                                    <span className='fw-normal text-center'>{ele.heading}</span>
                                                    <p>{ele.para}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </section>
                        }

                    </div>

                </section>
                :
                <>
                    <section className="navFiller d-flex align-items-center justify-content-center flex-column">
                        <div className='row mx-1 my-5 py-5'>
                            <span>&nbsp;</span>
                        </div>

                        <div className="row mx-1 my-5 px-5 py-4 align-items-center flex-column">
                            <span className="fs-custom-big">Error: 404</span>
                            <div className='d-flex align-items-center justify-content-center gap-3 w-100 my-3'>
                                <Link to="/" className='text-decoration-none'>
                                    <span className='fs-4 fw-bold text-white'>Home</span>
                                </Link>
                                <i className="fa-solid fa-angle-right text-white"></i>
                                <Link to="/products" className='text-decoration-none'>
                                    <span className='fs-4 fw-bold text-white'>Products</span>
                                </Link>
                                <span className='fs-4 fw-bold text-white'></span>
                            </div>
                        </div>
                    </section>

                    <section className='container h-50'>
                        <div className="d-flex justify-content-center align-items-center h-100 flex-column gap-3">
                            <span className='fs-1'>
                                <strong className='capitilize'>{param.productCode.split("_")[1]}&nbsp;</strong>
                                product not found
                            </span>
                            <Link to='/' className='btn-reset text-decoration-none py-2 px-3 rounded bg-theam text-white capitilize fs-3'>
                                Go Home
                            </Link>
                        </div>
                    </section>
                </>
            }
        </>
    )
}

export default ProductDetails
