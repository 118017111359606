import React, { useContext, useRef, useState } from 'react'
import GenralContext from '../context/GenralContext'
import { Link, useNavigate } from 'react-router-dom'

const AdminLogin = () => {

    const GC = useContext(GenralContext)
    const { host } = GC
    let navigate = useNavigate();

    const [data, setData] = useState()
    const handleOnChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const [spinner, setSpinner] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        let form = document.getElementById('adminLoginForm')
        setSpinner(true)
        await fetch(`${host}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify({
                "LoginID": data.LoginID,
                "LoginKey": data.LoginKey
            })
        }).then(async (result) => {
            let data = await result.json()

            if (data.token) {
                localStorage.setItem('auth-token', data.token)
                setSpinner(true)
                window.alert('Login Successfully')
                navigate('/admin')
                form.reset();
            } else {
                setSpinner(false)
                window.alert('Invalid ID/Password')
            }
        })
    }

    return (
        <>
            {/* <section id="navMarginStylish" className='mb-5'></section> */}
            <section className="navFiller d-flex align-items-center justify-content-center flex-column">
                <div className='row mx-1 my-5 py-5'>
                    <span>&nbsp;</span>
                </div>

                <div className="row mx-1 my-5 px-5 py-4 justify-content-center flex-column">
                    <span className="fs-custom-big">Admin login</span>

                    <div className='d-flex align-items-center justify-content-center gap-3 w-100 my-3'>
                        <Link to="/" className='text-decoration-none'><span className='fs-4 fw-bold text-white'>Home</span></Link>
                        <i className="fa-solid fa-angle-right text-white"></i>
                        <span className='fs-4 fw-bold text-white'>Admin Login</span>
                    </div>
                </div>
            </section>

            <section className='pb-5 mb-5 d-flex align-items-center justify-content-center'>
                <div className="container d-flex align-items-center justify-content-center gap-5 flex-wrap">

                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12 px-5 d-xxl-block d-lg-block d-md-none d-none ">
                        <img src={require('../images/Admin.webp')} alt="SampleImage" />
                    </div>

                    <div className="col-xxl-4 col-lg-4 col-md-11 col-11 mb-5 pb-5">
                        <form id='adminLoginForm' onSubmit={handleSubmit}>
                            <div className='my-5 border-bottom'>
                                <span className='fs-1 fw-bold ff-quicksand text-dark user-select-none'>Admin Login</span>
                            </div>

                            <div className="form-outline mb-4">
                                <input type="text" className="fs-3 form-control form-control-lg"
                                    placeholder="Admin ID*" name="LoginID" required={true} onChange={handleOnChange} autoComplete='on' />
                            </div>

                            <div className="form-outline mb-3">
                                <input type="password" className="fs-3 form-control form-control-lg"
                                    placeholder="Admin Password*" name="LoginKey" required={true} onChange={handleOnChange} autoComplete='on' />
                            </div>

                            <div className="form-outline mb-3">
                                <button type="button" className='btn-reset fs-5 text-decoration-underline text-theam fw-normal' data-bs-toggle="modal" data-bs-target="#forgotPassModal">Forgot Password</button>
                            </div>

                            <div className="text-center text-lg-start mt-4 pt-2">
                                <button type="submit" className="fs-3 btn btn-primary d-flex gap-3 align-items-center" style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }} disabled={spinner}>
                                    {spinner &&
                                        <span>
                                            {
                                                <>
                                                    <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Loading...</span>
                                                </>
                                            }
                                        </span>
                                    }
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export const ForgotPassModal = () => {

    const { host } = useContext(GenralContext)
    const form = useRef("")

    const [data, setData] = useState({})
    const handleOnChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleChangePassword = async (e) => {
        e.preventDefault();

        try {
            await fetch(`${host}/pass/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json'
                },
                body: JSON.stringify({
                    "key": data.key,
                    "password": data.password
                })
            }).then(async (res) => {
                let data = await res.json()
                if (res.status === 201) {
                    alert("Password changed!")
                } else if (res.status === 400) {
                    alert("Invalid Secret Key!")
                } else if (res.status === 404) {
                    alert("Server Error, KEY_FILE_MISSING")
                } else {
                    alert("Server Error")
                    console.log(res, data);
                }
            })
        } catch (error) {
            console.error(error);
            window.alert('Somthing went wrong with server')
        }
    }

    return (
        <>
            <div className="modal fade" id="forgotPassModal" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="forgotPassModalLabel" aria-hidden="true" style={{ backdropFilter: 'blur(5px)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header bg-theam">
                            <h1 className="modal-title fs-3 text-white" id="forgotPassModalLabel">Reset Password</h1>
                            <button type="button" className="btn-reset text-white" data-bs-dismiss="modal">
                                <i className="fa-solid fa-xmark fa-2x"></i>
                            </button>
                        </div>
                        <div className="modal-body p-4">

                            <form onSubmit={handleChangePassword} ref={form}>
                                <div className="form-outline mb-3">
                                    <input type="password" className="fs-3 form-control form-control-lg"
                                        placeholder="Secret Key*" name="key" required={true} autoComplete='on' onChange={handleOnChange} />
                                </div>
                                <div className="form-outline mb-3">
                                    <input type="password" className="fs-3 form-control form-control-lg"
                                        placeholder="New Password*" name="password" required={true} autoComplete='on' onChange={handleOnChange} />
                                </div>
                                <div className="form-outline d-flex justify-content-center">
                                    <button type="submit" className='btn-reset border rounded bg-theam py-2 px-3 text-white fs-4'>Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminLogin
