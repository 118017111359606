import React, { useEffect, useState } from 'react'

const TestimonialCard = (props) => {
    let { mess, name, degi, colo1, color2, rating } = props

    const [newText, setText] = useState({
        mess: '',
        flag: false
    })

    useEffect(() => {
        if (mess.length > 140) {
            setText({
                mess: mess.substring(0, 140) + " ",
                flag: true
            });
        } else {
            setText({
                mess: mess,
                flag: false
            })
        }
    }, [mess])

    const handleClick = (text) => {
        window.alert(text)
    }

    return (
        <>
            <div className="testimonial-card" style={{ '--color1': colo1, '--color2': color2 }}>
                <div className="testi-card-wrapper">
                    <p>
                        {newText.mess}
                        {
                            newText.flag &&
                            <button type="button" className='text-primary text-decoration-underline p-0 m-0 border-0 bg-transparent' onClick={() => handleClick(mess)}>more</button>
                        }
                    </p>
                    <div className="stars">
                        <div className='d-flex p-0 m-0'>
                            {rating === 1 &&
                                <i className={`fa-solid fa-star`}></i>
                            }

                            {rating === 2 &&
                                <>
                                    <i className={`fa-solid fa-star`}></i>
                                    <i className={`fa-solid fa-star`}></i>
                                </>
                            }

                            {rating === 3 &&
                                <>
                                    <i className={`fa-solid fa-star`}></i>
                                    <i className={`fa-solid fa-star`}></i>
                                    <i className={`fa-solid fa-star`}></i>
                                </>
                            }

                            {rating === 4 &&
                                <>
                                    <i className={`fa-solid fa-star`}></i>
                                    <i className={`fa-solid fa-star`}></i>
                                    <i className={`fa-solid fa-star`}></i>
                                    <i className={`fa-solid fa-star`}></i>
                                </>
                            }

                            {rating === 5 &&
                                <>
                                    <i className={`fa-solid fa-star`}></i>
                                    <i className={`fa-solid fa-star`}></i>
                                    <i className={`fa-solid fa-star`}></i>
                                    <i className={`fa-solid fa-star`}></i>
                                    <i className={`fa-solid fa-star`}></i>
                                </>
                            }

                            <i className={`fa-regular fa-star`}></i>
                            <i className={`fa-regular fa-star`}></i>
                            <i className={`fa-regular fa-star`}></i>
                            <i className={`fa-regular fa-star`}></i>
                            <i className={`fa-regular fa-star`}></i>
                        </div>
                    </div>
                    <div className="name-degination d-flex flex-column">
                        <span className="name">{name}</span>
                        <span className="degination">{degi}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TestimonialCard
