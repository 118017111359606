import React, { useContext, useEffect, useRef } from 'react'
import NormalSliderCard from '../template/NormalSliderCard'
import GenralContext from '../context/GenralContext'

const Home = () => {

    const GC = useContext(GenralContext)
    const { specializeData } = GC

    const handleProductSlider_left = () => {
        let contaier = document.getElementById('productCarousel_main')
        contaier.scrollLeft -= 180
    }

    const handleProductSlider_right = () => {
        let contaier = document.getElementById('productCarousel_main')
        contaier.scrollLeft += 180
    }

    const homePageVideo = useRef("")
    const handlePlayVideo = () => {
        homePageVideo.current.play()
    }

    useEffect(() => {
        handlePlayVideo()
    }, [])

    return (
        <>
            <div className="video-container" id='homeVideoContainer' style={{ zIndex: 0 }}>
                <video src={require('../video/introUs.mp4')} rel='preload' muted={true} loop={true} poster={require('../images/thumb_intro.webp')} style={{ width: '100%' }} ref={homePageVideo} onLoadedData={handlePlayVideo} />
            </div>

            <section id='whoWeAreSection' className='container my-5 py-4'>
                <div className="d-flex flex-wrap gap-5 justify-content-center">
                    <div className="col-xxl-7 col-lg-7 col-md-12 col-12">
                        <div className="row">
                            <div className='border-bottom py-3 mb-3'>
                                <span className='ff-quicksand mb-3' style={{ fontSize: '25px' }}>WHO WE ARE</span>
                            </div>

                            <p className="fs-3 ff-quicksand text-justify">
                                At The Concept Studio, we're not just in the business of interior design; we're in the business of turning your dreams into tangible, breathtaking spaces. With an unwavering commitment to excellence, we've scoured for innumerable assets and honed our craft to become your one-stop solution for all your interior needs.
                            </p>

                            <p className="fs-3 ff-quicksand text-justify">
                                Our motto is simple but profound: "Interiors beyond Imagination." We firmly believe that the interiors of a space should go beyond the ordinary and instead reflect the unique personality and aspirations of its occupants. This belief is at the core of everything we do, driving us to provide an exquisite array of luxury interior design solutions that cater to clients with diverse tastes, requirements, and budgets.
                            </p>

                            <p className="fs-3 ff-quicksand text-justify">
                                Our commitment to our clients is unwavering. We pledge to put forth our best efforts to ensure that the trifecta of quality, speed, and cost always aligns with the expectations of our cherished clients. We understand that every project is an opportunity to create something exceptional, and we take this responsibility seriously
                            </p>
                        </div>
                    </div>
                    <div className="col-xxl-3 col-lg-3 col-md-12 col-12">
                        <img src={require('../images/whoWeAre.webp')} alt="" width={'100%'} height={'100%'} />
                    </div>
                </div>
            </section>

            <section id="ourVissionSection" data-aos="fade-up" data-aos-duration="1500">
                <div className="w-100 d-flex align-items-center justify-content-center" id='ourMission'>
                    <div style={{ backgroundColor: 'rgba(255,255,255, 0.9)', maxWidth: '60rem' }} className='py-4 px-5 d-flex flex-column align-items-center'>
                        <span className="fs-1 ff-quicksand" style={{ fontWeight: 450 }}>Our Vision</span>
                        <p className='fs-3 text-dark text-center'>
                            We aspire to become the most admired Interior Product Studio through innovation and excellence in customer service, and to exceed our client's expectations.
                        </p>
                    </div>
                </div>
            </section>

            <section id="ourMissionSection" className='mb-5 pb-5' data-aos="fade-up" data-aos-duration="1500">
                <div className="w-100 d-flex align-items-center justify-content-center" id='ourMission'>
                    <div style={{ backgroundColor: 'rgba(255,255,255, 0.9)', maxWidth: '60rem' }} className='py-4 px-5 d-flex flex-column align-items-center'>
                        <span className="fs-1 ff-quicksand"style={{ fontWeight: 450 }}>Our Mission</span>
                        <p className='fs-3 text-dark text-center'>
                            To operate our business with care, honesty, and integrity, and strive to deliver exceptional interior design products to our clients within their schedule and budget, creating profitable growth in harmony.
                        </p>
                    </div>
                </div>
            </section>


            <section id="ourProductSection" className='mt-5' data-aos="fade-up" data-aos-duration="1500">
                <div className="d-flex flex-column align-items-center">
                    <span className='fw-normal mb-4' style={{ fontSize: '3rem' }}>We Specialize In</span>

                    <div className="px-5 w-100 d-flex align-items-center justify-content-center gap-3">
                        <button type="button" className='btn rounded-pill' style={{ height: '4rem', width: '4rem', minHeight: '4rem', minWidth: '4rem', background: 'rgb(0, 43, 99)' }} onClick={handleProductSlider_left}>
                            <i className="fa-solid fa-chevron-left text-white fa-2x"></i>
                        </button>

                        <div className='d-flex gap-4' id='productCarousel_main'>
                            {specializeData.map((ele) => {
                                return (
                                    <NormalSliderCard key={ele.id} img={ele.productImg} text={ele.productName} productCode={ele.productId} />
                                )
                            })}
                        </div>

                        <button type="button" className='btn rounded-pill' style={{ height: '4rem', width: '4rem', minHeight: '4rem', minWidth: '4rem', background: 'rgb(0, 43, 99)' }} onClick={handleProductSlider_right}>
                            <i className="fa-solid fa-chevron-right text-white fa-2x"></i>
                        </button>
                    </div>
                </div>
            </section>

            <section id='conceptStudioOffersSection' className='mb-5 pb-5' data-aos="fade-up" data-aos-duration="1500">
                <div className="my-4 px-3 d-flex d-flex flex-column align-items-center" data-aos="fade-up" data-aos-duration="2000">
                    <span className='ff-quicksand mb-4 fw-normal' style={{ fontSize: '25px' }}>&nbsp;Concept Studio Offers&nbsp;</span>
                    <div className='d-flex gap-5 flex-wrap justify-content-center'>
                        <div className="decmore-offer-card" data-aos="flip-right" data-aos-duration="2000" data-aos-delay="300">
                            <span className='fw-lighter'>Quality</span>
                            <p>Best quality at an affordable cost & easy availability through a vast & effective channel network & prompt efficient after-sales service</p>
                        </div>
                        <div className="decmore-offer-card" data-aos="flip-right" data-aos-duration="2000" data-aos-delay="300">
                            <span className='fw-lighter'>Innovation</span>
                            <p>At Concept Studio, we do not merely bank on the existing profile of successful products, but we also continuously introduce futuristic products for the benefit of our customers.</p>
                        </div>
                        <div className="decmore-offer-card" data-aos="flip-right" data-aos-duration="2000" data-aos-delay="300">
                            <span className='fw-lighter'>Belief</span>
                            <p>We at Concept Studio believe in "Interiors Beyond Imagination". Continuous improvement is an ongoing effort to improve the quality of products. </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home
