import React, { useEffect, useState } from 'react';
import GenralContext from './GenralContext';

const DataState = (props) => {

    const host = 'https://backend-server-pen6.onrender.com'
    // const host = 'http://localhost:5001'

    const [projectsInformation, setProjectsInformation] = useState()
    useEffect(() => {
        getTestimonialData();
        getAllProjects();
    }, [])

    const specializeData = [
        {
            id: 0,
            productImg: require('../images/products/acrylic/acrylic_1.webp'),
            stackImg: [
                require('../images/products/acrylic/acrylic_2.webp'),
                require('../images/products/acrylic/acrylic_3.webp'),
                require('../images/products/acrylic/acrylic_4.webp'),
                require('../images/products/acrylic/acrylic_5.webp')
            ],
            productId: 'product_arcylicSheet',
            productName: 'Acrylic Sheet',
            subHeading: 'Transforming Clarity into Creativity',
            para1: 'Unlock your creative potential with our premium acrylic sheets. Crafted to deliver exceptional clarity, brilliance, and durability, these versatile and transparent sheets offer a world of possibilities.',
            details: [
                {
                    id: 0,
                    heading: 'Exceptional Clarity',
                    para: 'Our acrylic sheets provide unparalleled optical clarity, allowing your designs to radiate with vivid colours and intricate details.',
                },
                {
                    id: 1,
                    heading: 'Built to Last',
                    para: 'Highly resistant to impact and weathering, our acrylic sheets are suitable for indoor applications, ensuring longevity.'
                },
                {
                    id: 2,
                    heading: 'UV Resistant',
                    para: `Don't worry about yellowing or deterioration in the sun; our acrylic sheets are UV-stabilized for lasting brilliance.`
                }
            ],
            application: null,
            para2: `Whether you're creating a captivating Kitchen/wardrobe/ our acrylic sheets are the go-to choice for professionals.`
        },
        {
            id: 1,
            productImg: require('../images/products/laminates/laminate_1.webp'),
            stackImg: [
                require('../images/products/laminates/laminate_2.webp'),
                require('../images/products/laminates/laminate_3.webp'),
                require('../images/products/laminates/laminate_4.webp'),
                require('../images/products/laminates/laminate_5.webp')
            ],
            productId: 'product_laminates',
            productName: 'Laminates',
            subHeading: 'Elevate Your Space with Laminates',
            para1: 'Laminates are the secret ingredient to transforming your interior spaces with style and functionality. Our extensive laminate collection offers a myriad of design possibilitie',
            details: [
                {
                    id: 0,
                    heading: 'Design Versatility',
                    para: `Dive into a world of colours, patterns, and textures, from high-gloss to fluted to matte finishes, to find the perfect laminate for your style.`
                },
                {
                    id: 1,
                    heading: 'Effortless Maintenance',
                    para: `Keeping your spaces pristine is a breeze with easy-to-clean laminates that look fresh and new with minimal effort. `
                },
                {
                    id: 2,
                    heading: 'Eco-Friendly Choices:',
                    para: `We care about the environment and offer eco-friendly laminates to promote a healthier indoor environment.`
                },
            ],
            application: [
                {
                    id: 0,
                    heading: 'Kitchen Surfaces',
                    para: 'Laminates are a popular choice for kitchen countertops and cabinets. Their durability and wide range of designs make them the perfect fit for this central space in your home.'
                },
                {
                    id: 1,
                    heading: 'Bathroom Elegance',
                    para: 'Transform your bathroom into a spa-like retreat with laminates that mimic the appearance of natural stone or wood. They are moisture-resistant and easy to clean, ensuring long-lasting beauty.'
                },
                {
                    id: 2,
                    heading: 'Office Sophistication',
                    para: 'In an office setting, laminates can create a professional and modern ambience. They are ideal for workstations, conference tables, and cabinetry.'
                }
            ],
            para2: `Concept Studio is committed to providing you with laminates that blend aesthetics and functionality seamlessly. Our expert team can assist you in selecting the ideal laminates for your projects, - ensuring that your design concepts come to life with precision and finesse. Whether you're renovating your home, designing a commercial space, or embarking on a new project, our laminates add the perfect finishing touch, elevating your spaces to unparalleled levels of style and sophistication.`
        },
        {
            id: 2,
            productImg: require('../images/products/mosaics/mosaics_1.webp'),
            stackImg: [
                require('../images/products/mosaics/mosaics_2.webp'),
                require('../images/products/mosaics/mosaics_3.webp'),
                require('../images/products/mosaics/mosaics_4.webp'),
                require('../images/products/mosaics/mosaics_5.webp'),
                require('../images/products/mosaics/mosaics_6.webp'),
            ],
            productId: 'product_mosaics',
            productName: 'Mosaics',
            subHeading: 'Unleash Your Creativity with Mosaics',
            para1: `Mosaics are your canvas for intricate storytelling through carefully arranged pieces. Dive into our mosaic collection, where artistry knows no bounds`,
            details: [
                {
                    id: 0,
                    heading: 'Intricate Design',
                    para: `Mosaics are your canvas for intricate storytelling through carefully arranged pieces. Dive into our mosaic collection, where artistry knows no bounds.`
                },
                {
                    id: 1,
                    heading: 'Diverse Materials',
                    para: `Choose from an array of materials, including glass, ceramic, and natural stone, to match your aesthetic preferences and project requirements.`
                },
                {
                    id: 2,
                    heading: 'Versatile Applications',
                    para: `Mosaics are suitable for backsplashes, accent walls and even outdoor spaces, adding a touch of luxury to your environment.`
                },
                {
                    id: 3,
                    heading: 'Your Unique Vision',
                    para: `Customize your mosaics with our options for creating one-of-a-kind designs tailored to your imagination.`
                },
            ],
            application: null,
            para2: `Whether you're an interior designer seeking to captivate your clients or a homeowner yearning to infuse your living space with beauty and character, mosaics offer a timeless and enduring solution. Explore the boundless potential of mosaics and elevate your design projects to a level of artistry that transcends time and trends. Let Concept Studio be your partner in crafting spaces that tell stories and evoke emotions.`
        },
        {
            id: 3,
            productImg: require('../images/products/plywood/plywood_1.jpg'),
            stackImg: [
                require('../images/products/plywood/plywood_2.png'),
                require('../images/products/plywood/plywood_3.png'),
                require('../images/products/plywood/plywood_4.png'),
                require('../images/products/plywood/plywood_5.jpg'),
            ],
            productId: 'product_plywood',
            productName: 'Plywood',
            subHeading: 'The Foundation of Excellence in Construction and Design',
            para1: `Plywood is the unsung hero of countless structures and projects. Our premium plywood selection is the cornerstone of strength, stability, and sustainability.`,
            details: [
                {
                    id: 0,
                    heading: 'Structural Integrity',
                    para: `Our plywood products are engineered to provide exceptional strength and stability, making them suitable for a wide range of applications, from furniture to construction.`
                },
                {
                    id: 1,
                    heading: 'Sustainability Matters',
                    para: `We prioritize sustainability by sourcing our plywood from responsibly managed forests, ensuring you can build with a clear conscience.`
                }
            ],
            application: [
                {
                    id: 0,
                    heading: 'Furniture Construction',
                    para: `Plywood is a staple in furniture making, serving as the primary material for creating everything from tables and chairs to cabinets and bookshelves.`
                },
                {
                    id: 1,
                    heading: 'Cabinetry',
                    para: `Plywood is the go-to choice for kitchen and bathroom cabinetry due to its durability and ability to withstand daily use.`
                },
                {
                    id: 2,
                    heading: 'Architectural Elements',
                    para: `Plywood can be used to create architectural features such as panels, partitions, and wall cladding, adding a unique design element to your space`
                },
            ],
            para2: `Whether you're constructing furniture, cabinetry, or structural elements, our plywood options offer the strength and adaptability you need for your projects.`
        },
        {
            id: 4,
            productImg: require('../images/products/vaneers/venners_1.png'),
            stackImg: [
                require('../images/products/vaneers/venners_2.png'),
                require('../images/products/vaneers/venners_3.png'),
                require('../images/products/vaneers/venners_4.png'),
                require('../images/products/vaneers/venners_5.png'),
            ],
            productId: 'product_vaneers',
            productName: 'Veneers',
            subHeading: 'Timeless Elegance with Natural Veneers',
            para1: `Veneers infuse warmth and beauty into your spaces with enhanced durability`,
            details: [
                {
                    id: 0,
                    heading: 'Wood Species Variety',
                    para: 'Choose from an array of wood species, each with its unique grain pattern and color, to achieve your desired aesthetic'
                },
                {
                    id: 2,
                    heading: 'Finishes that Inspire',
                    para: 'Our veneers are available in various finishes, from traditional to contemporary, allowing you to customize your interiors with finesse'
                },
                {
                    id: 3,
                    heading: 'Versatile Applications',
                    para: 'Veneers can be applied to furniture, wall panels, and more, bringin a touch of nature to your surroundings.'
                },
            ],
            application: [
                {
                    id: 0,
                    heading: 'Furniture',
                    para: 'Veneers add a touch of sophistication to furniture pieces, from tables to cabinets. Their flexibility allows for creative and intricate inlays and designs'
                },
                {
                    id: 1,
                    heading: 'Wall Panels',
                    para: 'Veneered wall panels create stunning accent walls, bringing the beauty of nature indoors. They can transform ordinary spaces into captivating focal points'
                },
                {
                    id: 2,
                    heading: 'Cabinetry',
                    para: 'Veneers are a popular choice for kitchen and bathroom cabinetry, adding a timeless and elegant appeal to your storage solutions.'
                },
                {
                    id: 3,
                    heading: 'Architectural Details',
                    para: 'Veneers can be used to enhance architectural details, such as columns, archways, and doors, infusing spaces with a sense of grandeur'
                },
            ],
            para2: `Whether you're designing furniture or wall panels, our veneers bring timeless elegance and the natural beauty of wood to your space.`
        },
        {
            id: 5,
            productImg: require('../images/products/cabinetHardware.webp'),
            productId: 'product_cabinetHardware',
            productName: 'Cabinet Handle',
            subHeading: 'Functional Beauty for Your Cabinets',
            para1: `The right cabinet hardware is the finishing touch that redefines your space. Our cabinet hardware collection comprises a wide range of knobs, pulls, hinges, and accessories that blend functionality with aesthetic appeal`,
            details: [
                {
                    id: 0,
                    heading: 'Design Selection',
                    para: 'Explore a diverse range of styles, finishes, and materials to find the perfect hardware that complements your cabinetry and decor.'
                },
                {
                    id: 1,
                    heading: 'Quality Craftsmanship',
                    para: `Our cabinet hardware is crafted with precision and attention to detail, ensuring long-lasting functionality and durability`
                },
                {
                    id: 2,
                    heading: 'Effortless Installation',
                    para: `Hassle-free installation makes updating your cabinets a breeze, instantly refreshing your space.`
                }
            ],
            application: null,
            para2: `Upgrade your cabinets and create a seamless, polished look with our cabinet hardware selection that's as functional as it is beautiful`
        },
        {
            id: 6,
            productImg: require('../images/products/kitchenHardware.webp'),
            productId: 'product_kitchenHardware',
            productName: 'Kitchen Hardware',
            subHeading: 'Elevate Your Kitchen with Premium Hardware',
            para1: `The kitchen is the heart of your home, and our kitchen hardware collection ensures it functions seamlessly while looking stunning.`,
            details: [
                {
                    id: 0,
                    heading: 'Handles and Pulls that Pop',
                    para: `Elevate your kitchen cabinetry with our selection of handles and pulls. They come in an array of finishes and designs, allowing you to add a touch of personality to your space. Whether you're aiming for a sleek, modern look or a more traditional feel, we have options that will resonate with your vision.`
                }
            ],
            application: null,
            para2: `Whether you're embarking on a full kitchen remodel or simply looking to refresh your space, our kitchen hardware collection offers a wide array of choices to cater to your unique tastes and needs. Experience the transformational power of Concept Studio kitchen hardware, where style meets practicality. Upgrade your kitchen with our selection of high-quality kitchen hardware, adding both style and convenience to your daily life.`
        },
        {
            id: 7,
            productImg: require('../images/products/woodenFlooring/woodenFlooring_1.jpg'),
            stackImg: [
                require('../images/products/woodenFlooring/woodenFlooring_2.jpg'),
                require('../images/products/woodenFlooring/woodenFlooring_3.jpg'),
                require('../images/products/woodenFlooring/woodenFlooring_4.jpg'),
                require('../images/products/woodenFlooring/woodenFlooring_5.jpg'),
            ],
            productId: 'product_woodenFlooring',
            productName: 'Wooden Flooring',
            subHeading: 'The Timeless Appeal of Wooden Flooring',
            para1: `Wooden flooring is more than just a surface to walk on; it's a statement of elegance and comfort. We offer a diverse range of wooden flooring options that seamlessly combine the beauty of nature with the durability of modern engineering.`,
            details: [
                {
                    id: 0,
                    heading: 'Warmth and Comfort',
                    para: 'Wooden floors exude warmth, making your space feel cory and Inviting. They are also naturally comfortable to walk on, providing a pleasant tactile experience'
                },
                {
                    id: 1,
                    heading: 'Timeless Aesthetic',
                    para: 'Wood never goes out of style. Its natural grain patterns and rich colors create a timeless and sophisticated look that enhances any interior design.'
                },
                {
                    id: 2,
                    heading: 'Durability',
                    para: 'Our wooden flooring options are built to withstand the test of time. They are resistant to wear, scratches, and stains, making them ideal for high-traffic areas.'
                },
                {
                    id: 3,
                    heading: 'Variety of Styles',
                    para: 'Whether you prefer the classic look of oak, the rustic charm of pine, or the exotic allure of bamboo, we offer a wide variety of wood species to match your aesthetic preferences.'
                },
            ]
        },
        {
            id: 8,
            productImg: require('../images/products/walls/high_1.webp'),
            stackImg: [
                require('../images/products/walls/high_2.webp'),
                require('../images/products/walls/high_3.webp'),
                require('../images/products/walls/high_4.webp'),
                require('../images/products/walls/high_5.webp'),
                require('../images/products/walls/high_6.webp'),
                require('../images/products/walls/high_7.webp'),
                require('../images/products/walls/hight_8.webp'),
                require('../images/products/walls/hight_9.webp'),
                require('../images/products/walls/hight_10.webp'),
                require('../images/products/walls/hight_11.webp'),
                require('../images/products/walls/hight_12.webp'),
                require('../images/products/walls/hight_13.webp'),
                require('../images/products/walls/hight_14.webp'),
                require('../images/products/walls/hight_15.webp'),
            ],
            productId: 'product_wallhighlighter',
            productName: 'Wall Highlighter',
            subHeading: 'Elevate walls with vibrant, luminous hues',
            para1: `Transform your living space with our exquisite collection of wall highlighters. Whether you're aiming for a modern and sleek ambience or a more classic and timeless feel, our carefully curated range of highlighters adds a touch of sophistication to any wall. With a palette of vibrant colours and a variety of textures, these highlighters effortlessly catch and reflect light, creating dynamic and visually stunning focal points in your room. Elevate your home decor with the subtle elegance and artistic flair of our wall highlighters, turning your walls into captivating works of art that leave a lasting impression on everyone who enters your space.`,
            details: [
                {
                    id: 0,
                    heading: 'Light-Reflecting Elegance',
                    para: 'Effortlessly catch and reflect light, adding sophistication and depth to your walls.'
                },
                {
                    id: 1,
                    heading: 'Dynamic Focal Points',
                    para: 'Transform your room into a visually stunning space with dynamic focal points.'
                },
                {
                    id: 2,
                    heading: 'Artistic Atmosphere',
                    para: 'Turn your walls into captivating works of art, enhancing the overall atmosphere of your home'
                },
                {
                    id: 3,
                    heading: 'Timeless Elegance, Modern Allure',
                    para: 'Merge the classic with the contemporary by choosing from our range that effortlessly blends timeless elegance with a modern allure.'
                },
            ],
            application: [
                {
                    id: 0,
                    heading: 'Luxury Residences',
                    para: 'Ideal for upscale residences, where the wall highlighters can be applied in living rooms, bedrooms, or dining areas to add a touch of luxury and sophistication.'
                },
                {
                    id: 1,
                    heading: 'Art Studios',
                    para: `Perfect for artists' studios seeking to experiment with unique wall treatments, turning the studio space into an inspiring and visually dynamic environment.`
                },
                {
                    id: 2,
                    heading: 'Corporate Offices',
                    para: `Elevate the aesthetics of corporate office spaces, especially in reception areas or executive offices, to make a strong and lasting impression on clients, partners, and employees.`
                },
                {
                    id: 3,
                    heading: 'Fine Dining',
                    para: 'Enhance the ambiance of fine dining establishments by incorporating wall highlighters to create a visually stunning and elegant atmosphere, setting the stage for an exceptional dining experience.'
                }
            ]
        }
    ]

    const decmoreProducts = [
        {
            id: 0,
            productName: 'idf panels',
            img: require('../images/decmore_products/idf-panels.jpg')
        },
        {
            id: 1,
            productName: 'modern art',
            img: require('../images/decmore_products/modern-art.jpg')
        },
        {
            id: 2,
            productName: 'flute panels',
            img: require('../images/decmore_products/flute-panels.jpg')
        },
        {
            id: 3,
            productName: 'stonre art',
            img: require('../images/decmore_products/stone-art.jpg')
        },
        {
            id: 4,
            productName: 'imperia',
            img: require('../images/decmore_products/imperia.jpg')
        },
        {
            id: 5,
            productName: 'millan',
            img: require('../images/decmore_products/milan.jpg')
        },
        {
            id: 6,
            productName: 'solid wood',
            img: require('../images/decmore_products/solid-wood.jpg')
        },
        {
            id: 7,
            productName: 'como',
            img: require('../images/decmore_products/como.jpg')
        },
    ]

    const contactDetails = {
        address: 'Warehouse No 2, Shree Vignesh Warehouse Complex, 13-A , Sector G, JK Rd, opposite RMJ Motors, Govindpura, Bhopal, Madhya Pradesh 462023',
        phone: '+917000416494'
    }

    const socialLinks = {
        insta: 'https://www.instagram.com/conceptstudiobhopal/',
        fb: 'https://www.facebook.com/ConceptStudioBhopal',
        whatsapp: 'https://wa.me/+919752411123',
        mail: 'srconceptstudio@gmail.com'
    }

    const [testimonialData, setTestimonialData] = useState()
    const getTestimonialData = async () => {
        try {
            await fetch(`${host}/testimonial/get/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(async (data) => {
                let result = await data.json()

                // sort data according to dates
                result.data.sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                });

                setTestimonialData(result.data)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const toDateString = (string) => {
        const curr = new Date(string)
        return `${curr.getDate() < 10 ? `0${curr.getDate()}` : `${curr.getDate()}`}/${(curr.getMonth() + 1) < 10 ? `0${curr.getMonth() + 1}` : `${curr.getMonth() + 1}`}/${curr.getFullYear()}`
    }

    const getAllProjects = async () => {
        await fetch(`${host}/project`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Aplication/json'
            }
        }).then(async (res) => {
            let temp = await res.json()

            // sort data according to dates
            temp.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });

            setProjectsInformation(temp)
        })
    }

    const [allBlogs_client, setAllBlogs_client] = useState([])
    const [isLoadingBlog_Client, setIsLoadingBlog_Client] = useState(false)
    useEffect(() => {
        (async () => {
            try {
                setIsLoadingBlog_Client(true)
                let raw = await fetch(`${host}/blogs`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                })

                let jsonData = await raw.json()
                setAllBlogs_client(jsonData)
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoadingBlog_Client(false)
            }
        })();
    }, [host, setAllBlogs_client, setIsLoadingBlog_Client])

    const [allComments, setAllComments] = useState([])
    useEffect(() => {
        (async () => {
            try {
                let raw = await fetch(`${host}/blogs/comment`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                })

                let jsonData = await raw.json()

                if (raw.status === 200) {
                    setAllComments(jsonData)
                }
            } catch (error) {
                console.log(error)
            }
        })();
    }, [host, setAllComments])

    return (
        <GenralContext.Provider value={{
            specializeData, contactDetails, socialLinks, testimonialData, host, toDateString,
            decmoreProducts, projectsInformation, setProjectsInformation, isLoadingBlog_Client, allBlogs_client,
            allComments, setAllComments
        }}>
            {props.children}
        </GenralContext.Provider>
    )
}

export default DataState;