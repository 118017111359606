import React from 'react'
import { Link } from 'react-router-dom'

const NormalSliderCard = (props) => {
    return (
        <>
            <div className='productSliderCard d-flex flex-column' data-aos="flip-right" data-aos-duration="2000">
                <img src={props.img} alt="" />
                <Link to={`/product/${props.productCode}`} className='text-decoration-none py-3 px-4 fs-3 ff-quicksand text-dark text-center' style={{fontWeight: 450}}>{props.text}</Link>
            </div>
        </>
    )
}

export default NormalSliderCard
