import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <>
            <section id="navMarginStylish"></section>
            <div class="d-flex align-items-center justify-content-center vh-75">
                <div class="text-center">
                    <h1 class="display-1 fw-bold">404</h1>
                    <p class="fs-2"> <span class="text-danger">Opps!</span> Page not found.</p>
                    <p class="lead fs-4">
                        The page you're looking for doesn't exist.
                    </p>
                    <Link to="/" className='btn btn-primary fs-4'>Go Home</Link>
                </div>
            </div>
        </>
    )
}

export default PageNotFound
