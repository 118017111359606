import React, { useContext, useState } from 'react'
import GenralContext from '../context/GenralContext'
import { Link } from 'react-router-dom'

const Contact = () => {

    const GC = useContext(GenralContext)
    const { host, contactDetails, socialLinks } = GC

    const [data, setData] = useState({})
    const handleOnChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const [sending, setSending] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSending(true)

        let form = document.getElementById('contactForm')
        await fetch(`${host}/contact/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify({
                "name": data.name,
                "email": data.email,
                "mess": data.mess,
                "phone": data.phoneNumber
            })
        }).then(async (result) => {
            let data = await result.json()
            if (data.data) {
                setSending(false)
                form.reset();
                window.alert('Thank you for your response.')
            } else {
                if (data.errors) {
                    window.alert(data.errors[0].msg)
                } else {
                    window.alert('Sorry! Somthing went wrong.')
                }
                console.log(data);
            }
        })
    }

    return (
        <>
            <section className="navFiller d-flex align-items-center justify-content-center flex-column">
                <div className='row mx-1 my-5 py-5'>
                    <span>&nbsp;</span>
                </div>

                <div className="row mx-1 my-5 px-5 py-4 justify-content-center flex-column">
                    <span className="fs-custom-big">Get in Touch with us</span>

                    <div className='d-flex align-items-center justify-content-center gap-3 w-100 my-3'>
                        <Link to="/" className='text-decoration-none'><span className='fs-4 fw-bold text-white'>Home</span></Link>
                        <i className="fa-solid fa-angle-right text-white"></i>
                        <span className='fs-4 fw-bold text-white'>Contact</span>
                    </div>
                </div>
            </section>

            <section id="contact">
                <div className="container my-5">
                    <div className="form-area d-flex gap-4 align-items-center justify-content-center">
                        <div className="col-xxl-6 col-lg-6 col-md-12 col-12">
                            <div className="row mx-0 contact-header mb-3">
                                <span className='line-height-1 mb-4' style={{ fontWeight: 400 }}>Get In Touch</span>
                                <div className="description">
                                    <ul className='px-0'>
                                        <li className='list-unstyled'>
                                            <p className='mb-2 fs-4 ff-quicksand letter-spacing-0' style={{ fontWeight: 450 }}>
                                                If you need any assistance, please call us on {contactDetails.phone} between 10:30 am to  8:30 pm , Monday-Saturday
                                            </p>
                                        </li>
                                        <li className='list-unstyled'>
                                            <p className='mb-2 fs-4 ff-quicksand letter-spacing-0' style={{ fontWeight: 450 }}>
                                                Sundays Open by Appointment Only
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                            <div className="ms-3">
                                <form onSubmit={handleSubmit} className='d-flex flex-column gap-3 custom-form-area' id='contactForm'>
                                    <div className="form-group-custom d-flex flex-column">
                                        <input type="text" placeholder='Your Name*' name='name' onChange={handleOnChange} required={true} />
                                    </div>
                                    <div className="form-group-custom d-flex flex-column no-spinner">
                                        <input type="number" name="phoneNumber" id="" onChange={handleOnChange} required={true} placeholder='+91 XXXXX XXXXX*' />
                                    </div>
                                    <div className="form-group-custom d-flex flex-column">
                                        <input type="mail" placeholder='yourmail@domain.com ' name='email' onChange={handleOnChange} />
                                    </div>
                                    <div className="form-group-custom d-flex flex-column">
                                        <textarea name="mess" cols="30" rows="5" placeholder='We appreciate your thoughts, feel free to say anything*' onChange={handleOnChange} required={true} />
                                    </div>
                                    <div className="form-group-custom d-flex flex-column">
                                        <button type="submit" className='d-flex align-items-center justify-content-center gap-2'>
                                            Send Message
                                            {
                                                sending === true &&
                                                <div class="spinner-border text-primary" role="status"></div>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-lg-5 col-0 d-xxl-flex d-lg-flex d-none gap-4 flex-column align-items-center">
                            <div className="d-flex justify-content-center">
                                <img src={require('../images/Logo-243x243.png')} alt="" />
                            </div>

                            <div className='d-flex gap-3 align-items-start ms-5 w-100'>
                                <div className="round-btn-2 col-2" style={{ '--colorCustom': '#002b63' }}>
                                    <i className="fa-solid fa-location-dot"></i>
                                </div>
                                <p className='ff-quicksand fs-4 col-10' style={{ fontWeight: 450 }}>{contactDetails.address}</p>
                            </div>

                            <div className='d-flex gap-3 align-items-center ms-5 w-100'>
                                <div className="round-btn-2 col-2" style={{ '--colorCustom': '#002b63' }}>
                                    <i className="fa-solid fa-envelopes-bulk"></i>
                                </div>
                                <p className='ff-quicksand fs-4 col-10' style={{ fontWeight: 450 }}>
                                    <a href={`mailto:${socialLinks.mail}`} className='text-decoration-none text-dark'>{socialLinks.mail}</a>
                                </p>
                            </div>

                            <div className='d-flex gap-3 align-items-center ms-5 w-100'>
                                <div className="round-btn-2 col-2" style={{ '--colorCustom': '#002b63' }}>
                                    <i className="fa-solid fa-square-phone"></i>
                                </div>
                                <p className='ff-quicksand fs-4 col-10' style={{ fontWeight: 450 }}>
                                    <a href={`tel:${contactDetails.phone}`} className='text-decoration-none text-dark'>{contactDetails.phone}</a>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section id="googlemap">
                <div className="container mb-5">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d117283.5818509717!2d77.3111444!3d23.2753822!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c69ec31040001%3A0xf0c3ccaf639904b!2sSR%20Concept%20studio!5e0!3m2!1sen!2sin!4v1691755433534!5m2!1sen!2sin" width="100%" height="350" style={{ border: 0 }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Store Loaction" />
                </div>
            </section>
        </>
    )
}

export default Contact
