import React from 'react'
import '../styles/SideComponent.scss'

const DecmoreProduct = (props) => {
    return (
        <>
            <div className="decmore-product-card-main" data-aos="flip-left" data-aos-duration="2000">
                <div className="img-container">
                    <img src={props.img} alt="" />
                </div>
            </div>
        </>
    )
}

export default DecmoreProduct