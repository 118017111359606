import React, { useEffect } from 'react'
import './styles/main.scss'
import { Route, Routes, HashRouter } from 'react-router-dom';
import Navbar from './Components/Navbar';
import './styles/main.scss';
import './styles/fonts.scss'
import './styles/utility.scss'
import Home from './Components/Home';
import AOS from 'aos';
import 'aos/dist/aos.css';
import GenralState from './context/GenralState';
import ProductDetails from './template/ProductDetails';
import Products from './Components/Products';
import Footer from './Components/Footer';
import Contact from './Components/Contact'
import Testimonial from './Components/Testimonial';
import Admin from './Components/Admin';
import AdminLogin, { ForgotPassModal } from './Components/AdminLogin';
import PageNotFound from './Components/PageNotFound';
import About from './Components/About';
import './styles/responsive.scss'
import Decmore from './Components/Decmore';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Studio from './Components/Studio';
import Project from './Components/Project';
import AllBlogs from './template/AllBlogs';
import BlogDetails from './template/BlogDetails';

function App() {
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <title>SR Concept Studio</title>
          <meta name="description" content="The Concept Studio one stop solution for all the interior needs has scoured for innumerable assets. We believe in providing “Interiors beyond Imagination” that empower us to give an assortment of extravagance interior design needs to our customers with different degrees and spending plans. Our promise is to put in our best effort to ensure the quality, speed and cost meet the expectation of our clients so that all of us should build a long and productive association." />
          <meta name="apple-mobile-web-app-title" content="SR Concept Studio" />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemprop="name" content="SR Concept Studio" />
          <meta name="keywords" content="Concept Studio, Concept Studio Bhopal, Interior Shope Bhopal, Interior Design, SR Concept Studio" />
          <meta itemprop="description" content="The Concept Studio one stop solution for all the interior needs has scoured for innumerable assets. We believe in providing “Interiors beyond Imagination” that empower us to give an assortment of extravagance interior design needs to our customers with different degrees and spending plans. Our promise is to put in our best effort to ensure the quality, speed and cost meet the expectation of our clients so that all of us should build a long and productive association." />
          <meta itemprop="image" content="https://scontent.fbho2-1.fna.fbcdn.net/v/t39.30808-6/336881024_1585016631995372_5664757801201055747_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=a2f6c7&_nc_ohc=_Lz_J8tAfY0AX-glLLb&_nc_ht=scontent.fbho2-1.fna&oh=00_AfA2k3RC_PfW1oMfLym_cP4JkWfFRuydQrfgICPsIPSpSg&oe=6511F9FF" />
        </Helmet>
      </HelmetProvider>

      <GenralState>
        <HashRouter>
          <Navbar />
          
          {/* Bootstrap modal starts here */}
          <ForgotPassModal />

          <Routes>
            <Route path="/admin" element={<Admin />} />
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/testimonial" element={<Testimonial />} />
            <Route path="/product/:productCode" element={<ProductDetails />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='admin/login' element={<AdminLogin />} />
            <Route path="/about" element={<About />} />
            <Route path='/decmore' element={<Decmore />} />
            <Route path='/studio' element={<Studio />} />
            <Route path='/projects' element={<Project />} />
            <Route path="/blogs" element={<AllBlogs />} />
            <Route path="/blog/details/:blog_id" element={<BlogDetails />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
          <Footer />
        </HashRouter>
      </GenralState>
    </>
  );
}

export default App;
