import React from 'react'
import { Link } from 'react-router-dom'

const Studio = () => {
    return (
        <>

            <section className="navFiller d-flex align-items-center justify-content-center flex-column">
                <div className='row mx-1 my-5 py-5'>
                    <span>&nbsp;</span>
                </div>

                <div className="row mx-1 my-5 px-5 py-4 justify-content-center flex-column">
                    <span className="fs-custom-big">Studio</span>

                    <div className='d-flex align-items-center justify-content-center gap-3 w-100 my-3'>
                        <Link to="/" className='text-decoration-none'><span className='fs-4 fw-bold text-white'>Home</span></Link>
                        <i className="fa-solid fa-angle-right text-white"></i>
                        <span className='fs-4 fw-bold text-white'>Studio</span>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className='h-50 d-flex justify-content-center align-items-center'>
                    <span className="fs-1 text-theam fst-italic">
                        Comming Soon
                    </span>
                </div>
            </div>
        </>
    )
}

export default Studio