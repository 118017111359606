import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BlogCard from '../Components/blog/BlogCard'
import GenralContext from '../context/GenralContext'
import '../styles/blog.scss'

const CommnetBox = ({ text, name, date }) => {
    const handlePopUp = () => {
        alert(text)
    }

    return (
        <>
            <div className="col-lg-8 col-md-10 col-12 bg-comment-random rounded-3">
                <div className="py-3 px-4 shadow-sm">
                    <div className="d-flex justify-content-between flex-wrap align-items-center mb-2">
                        <span className='d-block fs-5 text-capitalize'>- {name}</span>
                        <span className='d-block fs-5'>{date}</span>
                    </div>
                    {text?.length > 150 ?
                        <span className='fs-4'>
                            {text.substring(0, 150)}... <button type="button" className="btn-reset text-white fw-semibold text-decoration-underline p-0 m-0" onClick={handlePopUp}>more</button>
                        </span>
                        :
                        <span className='fs-4'> {text} </span>
                    }
                </div>
            </div>
        </>
    )
}

const BlogDetails = () => {

    const { allBlogs_client, allComments, setAllComments, host, isLoadingBlog_Client } = useContext(GenralContext)
    const { blog_id } = useParams()

    const [formData, setFormData] = useState(null)
    const handleOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const [isLoading, setIsLoading] = useState(false)
    const handlePostComment = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {

            let raw = await fetch(`${host}/blogs/comment`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    name: formData?.name,
                    email: formData?.email,
                    msg: formData?.mess ?? "",
                    _id: blog_id
                })
            })

            let jsonData = await raw.json()

            if (raw.status === 201) {
                setAllComments([...allComments, jsonData])
                alert("Thanks for you feedback")
                e.target.reset()
            } else {
                alert(jsonData)
            }

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    const [unauthorize, setUnauthorize] = useState(false)
    useEffect(() => {
        setUnauthorize(() => {
            let checks = allBlogs_client.find(ele => ele._id === blog_id)
            return checks ? false : true
        })
    }, [setUnauthorize, allBlogs_client, blog_id])


    return <>
        {unauthorize ?
            <>
                <section id="navMarginStylish"></section>
                <div class="d-flex align-items-center justify-content-center vh-75">
                    <div class="text-center">
                        <h1 class="display-1 fw-bold">404</h1>
                        <p class="fs-2"> <span class="text-danger">Opps!</span> Blog not found</p>
                        <p class="lead fs-4">
                            The page you're looking for doesn't exist
                        </p>
                        <Link to="/blogs" className='btn btn-primary fs-4'>Go To Blog</Link>
                    </div>
                </div>
            </>
            :
            <>
                {isLoadingBlog_Client === true ?
                    <div className='w-100 d-flex justify-content-center align-items-center'>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    :
                    <>
                        <section className="navFiller d-flex align-items-center justify-content-center flex-column mb-5">
                            <div className='row mx-1 my-5 py-5'>
                                <span>&nbsp;</span>
                            </div>
                            <div className="row mx-1 my-5 px-5 py-4 justify-content-center flex-column">

                                <span className="fs-custom-big d-inline-flex justify-content-center mx-auto px-0 text-capitalize">
                                    {allBlogs_client.find(ele => ele._id === blog_id)?.text?.length > 75 ?
                                        allBlogs_client.find(ele => ele._id === blog_id)?.text.substring(0, 75) + "..."
                                        :
                                        allBlogs_client.find(ele => ele._id === blog_id)?.text
                                    }
                                </span>

                                <div className='d-flex align-items-center justify-content-center gap-3 w-100 my-3'>
                                    <Link to="/" className='text-decoration-none'><span className='fs-4 fw-bold text-white'>Home</span></Link>
                                    <i className="fa-solid fa-angle-right text-white"></i>
                                    <span className='fs-4 fw-bold text-white'>Blogs</span>
                                    <i className="fa-solid fa-angle-right text-white"></i>
                                    <span className='fs-4 fw-bold text-white text-capitalize'>
                                        {allBlogs_client.find(ele => ele._id === blog_id)?.text?.length > 75 ?
                                            allBlogs_client.find(ele => ele._id === blog_id)?.text.substring(0, 75) + "..."
                                            :
                                            allBlogs_client.find(ele => ele._id === blog_id)?.text
                                        }
                                    </span>
                                </div>
                            </div>
                        </section>

                        <section className='my-5 pt-1'>
                            <div className="container">
                                <div className='my-5' style={{ fontSize: '160%' }}>
                                    <div id='blog-main-container' dangerouslySetInnerHTML={{ '__html': allBlogs_client.find(ele => ele._id === blog_id)?.details }}></div>
                                </div>


                                {allComments.filter((ele) => ele.ofBlog === blog_id)?.length > 0 &&
                                    <div className="my-5 pt-3">
                                        <div className='my-3'>
                                            <span className="width-fit text-theam text-center fs-1 d-flex gap-2 align-items-center">
                                                Comments
                                                <span className='fs-2'>
                                                    ({allComments.filter((ele) => {
                                                        return ele.ofBlog === blog_id
                                                    })?.length
                                                    })
                                                </span>
                                            </span>
                                        </div>

                                        <div className='my-3'>
                                            <div className="d-flex flex-wrap gap-4">
                                                {allComments.filter((ele) => {
                                                    return ele.ofBlog === blog_id
                                                }).map((cmt) => {
                                                    return (
                                                        <CommnetBox text={cmt?.msg} key={`blog-comment-${cmt._id}`} name={cmt?.name} date={new Date(cmt?.createdAt).toLocaleDateString()} />
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="my-5 pt-3">
                                    <div className='my-3'>
                                        <span className="width-fit text-theam text-center fs-1">Push your Comments</span>
                                    </div>

                                    <div className='my-3 col-lg-6 col-md-8 col-12'>
                                        <div className="d-flex flex-wrap gap-4 w-100">
                                            <form onSubmit={handlePostComment} className='d-flex flex-column gap-3 custom-form-area w-100'>
                                                <div className="form-group-custom d-flex flex-column">
                                                    <input type="text" placeholder='Your Name*' name='name' onChange={handleOnChange} required={true} disabled={isLoading} />
                                                </div>
                                                <div className="form-group-custom d-flex flex-column">
                                                    <input type="mail" placeholder='yourmail@domain.com (Optional)' name='email' onChange={handleOnChange} disabled={isLoading} />
                                                </div>
                                                <div className="form-group-custom d-flex flex-column">
                                                    <textarea name="mess" cols="30" rows="5" placeholder='We appreciate your thoughts, feel free to say anything*' onChange={handleOnChange} required={true} disabled={isLoading} />
                                                </div>
                                                <div className="form-group-custom d-flex flex-column">
                                                    <button type="submit" className='d-flex align-items-center justify-content-center gap-2' disabled={isLoading}>
                                                        Comment Now
                                                        {isLoading &&
                                                            <div className="spinner-border text-primary" role="status"></div>
                                                        }
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {allBlogs_client?.length >= 2 &&
                            <section className="my-5 py-4">
                                <div className="container">
                                    <div className='px-3'>
                                        <span className="width-fit text-theam text-center fs-1">More Blogs</span>
                                    </div>

                                    <div className='d-flex flex-wrap w-100'>
                                        {allBlogs_client.filter(ele => { return ele._id !== blog_id }).map((ele) => {
                                            return (
                                                <BlogCard data={ele} key={ele._id} />
                                            )
                                        })}
                                    </div>
                                </div>
                            </section>
                        }
                    </>
                }
            </>
        }
    </>
}

export default BlogDetails