import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const BlogCard = (props) => {

    let { createdAt, card_img, text, subDetails, status, _id, details } = props.data
    let { admin_mode = false, setNav, setBlogContent, setBlogFormData, allBlogs, setAllBlogs, host } = props

    const [formProcess, setFormProcess] = useState(false)
    const handleDeleteBlog = async (_id) => {
        // eslint-disable-next-line
        let cnf = confirm("Are you sure want to delete this blog? This process can't be undone!")
        if (cnf === true) {
            setFormProcess(true)
            try {

                let raw = await fetch(`${host}/blogs/`, {
                    method: 'DELETE',
                    headers: {
                        'content-type': 'application/json',
                        'auth-token': localStorage.getItem('auth-token')
                    },
                    body: JSON.stringify({
                        _id: _id
                    })
                })

                if (raw.status === 200) {
                    setAllBlogs(allBlogs.filter((ele) => ele._id !== _id))
                    alert("Blog Deleted!")
                }

            } catch (error) {
                console.log(error);
                alert("Server Error")
            } finally {
                setFormProcess(false)
            }
        }
    }

    const handleEditBlog = (_id) => {
        // eslint-disable-next-line
        let cnf = confirm("Edit Blog ?")

        if (cnf === true) {
            setFormProcess(true)
            setBlogContent(details)
            setBlogFormData(() => {
                return {
                    'title': text,
                    'img_url': card_img,
                    'details': details,
                    'card_text': subDetails,
                    'update_flag': true,
                    'blog_id': _id
                }
            })
            setNav(() => {
                return {
                    contact: false,
                    testimonial: false,
                    project: false,
                    postBlog: true,
                    blog: false,
                    blogComments: false,
                }
            })
            setFormProcess(false)
        }
    }

    const handleToggleStatus = async (_id) => {
        // eslint-disable-next-line
        let cnf = confirm("Are you sure want change it's status ?")

        if (cnf === true) {
            try {
                setFormProcess(true)
                let raw = await fetch(`${host}/blogs/toggle-status`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'auth-token': localStorage.getItem('auth-token')
                    },
                    body: JSON.stringify({
                        'flag': !status,
                        '_id': _id
                    })
                })

                console.log(!status, _id);

                let jsonData = await raw.json()

                if (raw.status === 200) {
                    setAllBlogs(() => {
                        let temp = allBlogs.filter((ele) => ele._id !== _id)
                        let main = allBlogs.find((ele) => ele._id === _id)

                        main.status = !status

                        temp.push(main)
                        return temp
                    })
                    alert("Visibility status has been changed!")
                } else {
                    console.log(raw);
                    alert(jsonData)
                }

            } catch (error) {
                console.log(error);
                alert("Server Error")
            } finally {
                setFormProcess(false)
            }
        }
    }

    return (
        <>
            <div className="col-lg-3 col-md-4 col-12">
                <div className="p-3">
                    <div className="rounded-4 overflow-hidden border" data-aos={'flip-left'} data-aos-duration={2000}>
                        <div className='w-100 position-relative'>
                            <Link to={`/blog/details/${_id}`} className='text-dark text-decoration-none fs-2 fw-semibold text-truncate d-block col-12'>
                                <img src={card_img} alt="" className='w-100' style={{ aspectRatio: '3/2', objectFit: 'cover' }} />
                            </Link>
                            {admin_mode &&
                                <div className='position-absolute top-0 m-3 d-flex gap-3' style={{ right: 0 }}>
                                    <button type="button" className={`${formProcess ? 'opacity-25' : ''} lh-1 m-0 p-0 p-3 rounded-circle border-0 bg-warning text-white`} onClick={() => { handleToggleStatus(_id) }} disabled={formProcess}>
                                        {status ?
                                            <i className="fa-solid fa-eye"></i>
                                            :
                                            <i className="fa-solid fa-eye-slash"></i>
                                        }
                                    </button>
                                    <button type="button" className={`${formProcess ? 'opacity-25' : ''} lh-1 m-0 p-0 p-3 rounded-circle border-0 bg-success text-white`} onClick={() => { handleEditBlog(_id) }} disabled={formProcess}>
                                        <i className="fa-solid fa-pen-to-square"></i>
                                    </button>
                                    <button type="button" className={`${formProcess ? 'opacity-25' : ''} lh-1 m-0 p-0 p-3 rounded-circle border-0 bg-danger text-white`} onClick={() => { handleDeleteBlog(_id) }} disabled={formProcess}>
                                        <i className="fa-solid fa-trash"></i>
                                    </button>
                                </div>
                            }
                        </div>

                        <div className='w-100 px-2 py-3'>
                            <div>
                                <Link to={`/blog/details/${_id}`} className='text-dark text-decoration-none fs-2 fw-semibold text-truncate d-block col-12'>
                                    {text}
                                </Link>
                            </div>
                            <div>
                                <span className="fs-4">
                                    {subDetails?.length > 70 ?
                                        subDetails.substring(0, 70) + "..."
                                        :
                                        subDetails
                                    }
                                </span>
                            </div>

                            {admin_mode === true &&
                                <>
                                    <hr />
                                    <div>
                                        <span className='fs-5'>Created on: {new Date(createdAt).toLocaleDateString()}</span>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BlogCard