import React, { useContext, useState } from 'react'
import TestimonialCard from './TestimonialCard'
import GenralContext from '../context/GenralContext'
import { Link } from 'react-router-dom';

const Testimonial = () => {

    const GC = useContext(GenralContext);
    const { testimonialData, host } = GC;

    const handleLeftClick = () => {
        let container = document.querySelector('#testimonial-card-container');
        let cardWidth = container.querySelector('.testimonial-card').clientWidth + 10
        container.scrollLeft -= cardWidth
    }

    const handleRightClick = () => {
        let container = document.querySelector('#testimonial-card-container');
        let cardWidth = container.querySelector('.testimonial-card').clientWidth + 10
        let limit = (container.querySelectorAll('.testimonial-card').length - 1) * cardWidth

        if (container.scrollLeft >= limit) {
            container.scrollLeft = 0
        } else {
            container.scrollLeft += cardWidth
        }
    }

    const [data, setData] = useState({})
    const handleOnChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const [sending, setSending] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSending(true)
        let form = document.getElementById('testimonialForm')

        await fetch(`${host}/testimonial/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json'
            },
            body: JSON.stringify({
                "name": data.name,
                "email": data.email,
                "degi": data.degi,
                "rating": data.rating,
                "mess": data.mess
            })
        }).then(async (result) => {
            let data = await result.json()
            if (data) {
                form.reset();
                setSending(false)
                window.alert('Thank you for your response.')
            } else {
                window.alert('Sorry! Somthing went wrong with our side please try again after some time.')
                console.log(data);
            }
        })
    }

    return (
        <>
            <section className="navFiller d-flex align-items-center justify-content-center flex-column">
                <div className='row mx-1 my-5 py-5'>
                    <span>&nbsp;</span>
                </div>

                <div className="row mx-1 my-5 px-5 py-4 justify-content-center flex-column">
                    <span className="fs-custom-big">Testimonial</span>

                    <div className='d-flex align-items-center justify-content-center gap-3 w-100 my-3'>
                        <Link to="/" className='text-decoration-none'><span className='fs-4 fw-bold text-white'>Home</span></Link>
                        <i className="fa-solid fa-angle-right text-white"></i>
                        <span className='fs-4 fw-bold text-white'>Testimonial</span>
                    </div>
                </div>
            </section>

            <section id="testimonial_banner">
                <div className="d-flex my-5 d-flex justify-content-center">
                    <span className="text-theam" style={{ fontSize: '3rem' }}>
                        Our Happy Clients
                    </span>
                </div>

                <div className="container d-flex flex-xxl-nowrap flex-lg-nowrap flex-wrap-reverse align-items-center justify-content-center flex-xxl-row flex-lg-row gap-4">
                    <div className='testimonial-card-container-custom'>
                        <div className="custom-carousel d-flex justify-content-center align-items-center gap-3 testimonial-responsive-custom">
                            <div className="controller d-xxl-block d-md-block d-sm-none d-none">
                                <button type="button" onClick={handleLeftClick}>
                                    <i className="fa-solid fa-angle-left"></i>
                                </button>
                            </div>

                            <div className="d-flex" id='testimonial-card-container'>
                                {!testimonialData &&
                                    <div className='w-100 d-flex align-items-center justify-content-center px-5'>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                }

                                {testimonialData &&
                                    testimonialData.map((ele, index) => {
                                        return <TestimonialCard mess={ele.mess} name={ele.name} degi={ele.degi} color1={ele.color1} color2={ele.color2}
                                            rating={ele.rating} key={`${ele.name}likesRatingKey${index + 1}`} index={index} id={ele._id} />
                                    })
                                }
                            </div>

                            <div className="controller d-xxl-block d-md-block d-sm-none d-none">
                                <button type="button" onClick={handleRightClick}>
                                    <i className="fa-solid fa-angle-right"></i>
                                </button>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center my-5 gap-5 d-xxl-none d-md-none d-sm-flex d-flex'>
                            <button type="button" onClick={handleLeftClick} className='btn-reset bg-theam rounded-circle cursor-pointer' style={{ height: '4rem', width: '4rem' }}>
                                <i className="fa-solid fa-angle-left fa-2x text-white"></i>
                            </button>

                            <button type="button" onClick={handleRightClick} className='btn-reset bg-theam rounded-circle cursor-pointer' style={{ height: '4rem', width: '4rem' }}>
                                <i className="fa-solid fa-angle-right fa-2x text-white"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </section>

            <section id="feedback-form" className='my-5 pb-5'>
                <div className="container">
                    <div className="form-area d-flex gap-4 align-items-center justify-content-center">
                        <div className="col-xxl-6 col-lg-6 col-md-12 col-12">
                            <div className="row mx-0 contact-header mb-3">
                                <span className='text-theam line-height-1' style={{ fontWeight: 400 }}>Your feedback is</span>
                                <span className='text-theam line-height-1 fs-1 ps-4' style={{ fontWeight: 450 }}>Important for us</span>
                            </div>

                            <div className="ms-3">
                                <form onSubmit={handleSubmit} id='testimonialForm' className='d-flex flex-column gap-3 custom-form-area'>
                                    <div className="form-group-custom d-flex flex-column">
                                        <input type="text" placeholder='Your Name' name='name' onChange={handleOnChange} required />
                                    </div>
                                    <div className="form-group-custom d-flex justify-content-between">
                                        <input type="email" placeholder='yourmail@domain.com' style={{ width: '49%' }} name='email' onChange={handleOnChange} />
                                        <input type="text" name="degi" placeholder='Designation eg.. Customer' style={{ width: '49%' }} onChange={handleOnChange} />
                                    </div>
                                    <div className="form-group-custom d-flex justify-content-between w-100">
                                        <select name="rating" className='w-100' defaultValue={'DEFAULT'} onChange={handleOnChange} required>
                                            <option value="DEFAULT" className='d-none' disabled>Rate us out of 5</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                        </select>
                                    </div>
                                    <div className="form-group-custom d-flex flex-column">
                                        <textarea name="mess" id="mess" cols="30" rows="5" placeholder='We appreciate your thoughts, feel free to say anything' onChange={handleOnChange} required />
                                    </div>
                                    <div className="form-group-custom d-flex flex-column">
                                        <button type="submit" className='d-flex align-items-center justify-content-center gap-2'>
                                            Send Message
                                            {sending === true &&
                                                <div class="spinner-border text-primary" role="status"></div>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-lg-5 col-0 d-xxl-block d-lg-block d-none">
                            <img src={require('../images/ContactUs.webp')} alt="" width={"100%"} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial
