import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import GenralContext from '../context/GenralContext'

const Footer = () => {

    const GC = useContext(GenralContext)
    const { socialLinks } = GC

    const location = useLocation();
    const [footerColor, setFooterColor] = useState()

    useEffect(() => {
        if (location.pathname === '/admin') {
            setFooterColor('#184d7a')
        } else {
            setFooterColor('#184d7a')
        }

    }, [location])


    return (
        <>
            <footer id='footer-main-container' style={{ backgroundColor: footerColor }}>
                <div className="container">
                    <div className="footer-wrapper d-flex flex-wrap justify-content-between align-items-center">
                        <div className="logo-box px-5 mb-5">
                            <Link to="/">
                                <img src={require('../images/Logo-243x243.png')} alt="" />
                            </Link>
                        </div>

                        <div className="d-flex align-items-center justify-content-center flex-wrap">
                            <div className="d-flex flex-wrap">
                                <div className='quick-links px-5'>
                                    <span className='fs-2 fw-bold text-white'>Quick Links</span>
                                    <ul className='svg-listing-1 p-0 mt-2'>
                                        <li><Link to="/products">Products</Link></li>
                                        <li><Link to="/decmore">Decmore</Link></li>
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/testimonial">Testimonial</Link></li>
                                        <li><Link to="/projects">Projects</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>

                                <div className='quick-links px-5'>
                                    <span className='fs-2 fw-bold text-white'>We Specialize in</span>
                                    <ul className='svg-listing-1 p-0 mt-2'>
                                        <li><Link to="/product/product_arcylicSheet">Arcylic Sheet</Link></li>
                                        <li><Link to="/product/product_mosaics">Mosaics</Link></li>
                                        <li><Link to="/product/product_plywood">Plywood</Link></li>
                                        <li><Link to="/product/product_cabinetHardware">Cabinet Hardware</Link></li>
                                        <li><Link to="/product/product_kitchenHardware">Kitchen Hardware</Link></li>
                                        <li><Link to="/product/product_laminates">Laminates</Link></li>
                                    </ul>
                                </div>

                                <div className='quick-links px-5'>
                                    <span className='fs-2 fw-bold text-white'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <ul className='svg-listing-1 p-0 mt-2'>
                                        <li><Link to="/product/product_vaneers">Vaneers</Link></li>
                                        <li><Link to="/product/product_woodenFlooring">Wooden Flooring</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="d-flex flex-column justify-content-evenly mt-xxl-0 mt-xl-0 mt-lg-5 mt-5">
                                <div className='px-5 d-flex align-items-center justify-content-center flex-column'>
                                    <span className='fs-2 fw-bold text-white mb-1'>Connect with us</span>
                                    <ul className='p-0 mt-2 d-flex flex-row gap-4'>
                                        <li className='list-unstyled'>
                                            <div className="round-btn">
                                                <a href={socialLinks.insta} target="_blank" rel="noopener noreferrer">
                                                    <i className="fa-brands fa-square-instagram"></i>
                                                </a>
                                            </div>
                                        </li>

                                        <li className='list-unstyled'>
                                            <div className="round-btn">
                                                <a href={socialLinks.fb} target="_blank" rel="noopener noreferrer">
                                                    <i className="fa-brands fa-square-facebook"></i>
                                                </a>
                                            </div>
                                        </li>

                                        <li className='list-unstyled'>
                                            <div className="round-btn">
                                                <a href={socialLinks.whatsapp} target="_blank" rel="noopener noreferrer">
                                                    <i className="fa-brands fa-square-whatsapp"></i>
                                                </a>
                                            </div>
                                        </li>

                                        <li className='list-unstyled'>
                                            <div className="round-btn">
                                                <a href={`mailto:${socialLinks.mail}`}>
                                                    <i className="fa-solid fa-square-envelope"></i>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <hr style={{ color: "#fff" }} className='mb-4' />
                    <div className="copyright-text d-flex align-items-center justify-content-center px-0">
                        <p className="mb-0 text-white fs-4">
                            {/* © Copyright 2023 by Concept Studio */}
                            Design & Developed by - <a href="https://freakingminds.in/" target="_blank" rel="noopener noreferrer" className="text-white">Freaking Minds</a>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
