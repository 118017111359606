import React, { useContext } from 'react'
import GenralContext from '../context/GenralContext'
import SpecializeBox from '../template/SpecializeBox'
import { Link } from 'react-router-dom'

const Products = () => {

    const GC = useContext(GenralContext)
    const { specializeData } = GC

    return (
        <>
            <section className="navFiller d-flex align-items-center justify-content-center flex-column">
                <div className='row mx-1 my-5 py-5'>
                    <span>&nbsp;</span>
                </div>

                <div className="row mx-1 my-5 px-5 py-4 justify-content-center flex-column">
                    <span className="fs-custom-big">Our Products</span>

                    <div className='d-flex align-items-center justify-content-center gap-3 w-100 my-3'>
                        <Link to="/" className='text-decoration-none'><span className='fs-4 fw-bold text-white'>Home</span></Link>
                        <i className="fa-solid fa-angle-right text-white"></i>
                        <span className='fs-4 fw-bold text-white'>Products</span>
                    </div>
                </div>
            </section>

            <section id="section2" className='navPadding my-5'>
                <div className="container">
                    <div className="row justify-content-center">
                        <span className="width-fit text-theam text-center" style={{fontSize: '3rem'}}>We Specialize In</span>
                    </div>
                    <div className="d-flex gap-5 flex-wrap px-5 mx-5 mt-5 justify-content-center">
                        {specializeData.map((ele) => {
                            return (
                                <SpecializeBox key={ele.id} icon={ele.productImg} text={ele.productName} baseColor={ele.baseColor} productCode={ele.productId} />
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Products
