import React, { useContext } from 'react'
import DecmoreProduct from './DecmoreProduct'
import GenralContext from '../context/GenralContext'

const Decmore = () => {

    const { decmoreProducts } = useContext(GenralContext)

    return (
        <>
            <section className="d-xxl-block d-md-block d-sm-none d-none position-absolute w-100" style={{ minHeight: '15rem', backgroundColor: '#2222222e', backdropFilter: 'blur(10px)', zIndex: 2 }}>
            </section>

            <section>
                <div id="decmore-corousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={require('../images/decmore/como.jpg')} className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={require('../images/decmore/imperial.jpg')} className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={require('../images/decmore/milan.jpg')} className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={require('../images/decmore/modernArt.jpg')} className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={require('../images/decmore/solidWood.jpg')} className="d-block w-100" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={require('../images/decmore/stoneArt.jpg')} className="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#decmore-corousel" data-bs-slide="prev">
                        <i className="fa-solid fa-circle-chevron-left text-white fa-3x"></i>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#decmore-corousel" data-bs-slide="next">
                        <i className="fa-solid fa-circle-chevron-right text-white fa-3x"></i>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </section>

            <section>
                <div className="container my-5">
                    <div className="w-100 d-flex flex-column align-items-center">
                        <div className="row px-5 py-3 w-100 align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                            <span className="text-dark fw-normal ff-quicksand width-fit my-4 text-theam" style={{ fontSize: '3.5rem' }}>&nbsp;About Us&nbsp;</span>
                            <p className='ff-quicksand fs-3 mx-xxl-5 mx-md-5 mx-sm-0 mx-0 text-center'>
                                To satisfy the increasing demand for arts & crafts in interior decoration and to produce creative, soft, and rich furnishing, Decmore Panels LTD was established. Decmore Panels Ltd is an innovative designer and manufacturer of decorative finished panels for commercial, residential, and consumer home decor industries, dedicated to developing wonder-struck interior environments with a modern outlook.
                            </p>
                            <p className='ff-quicksand fs-3 mx-xxl-5 mx-md-5 mx-sm-0 mx-0 text-center'>
                                With a firm commitment to quality, they are manufacturing and exporting a comprehensive range of interior decorative finished panels. Their panels are manufactured in India and use high-grade raw materials. In addition to this, their panels are finished, packed well individually, and are known for easy installation and maintenance-free operation.
                            </p>
                            <p className='ff-quicksand fs-3 mx-xxl-5 mx-md-5 mx-sm-0 mx-0 text-center'>
                                Decmore Panels are not only easy to implement but suitable for multiple wooden surfaces and make a huge impact on the spaces where you would love to indulge. They add freshness, vitality, and a touch of definition to every living space. So, if you are planning to enhance your furniture, walls, doors, almirah, ceiling, and pillars, contact Concept Studio now.
                            </p>
                        </div>

                        <div className="my-4 px-3 d-flex d-flex flex-column align-items-center" data-aos="fade-up" data-aos-duration="2000">
                            <span className="text-dark fw-normal ff-quicksand width-fit my-4 text-theam" style={{ fontSize: '3.5rem' }}>&nbsp;Our Services&nbsp;</span>
                            <div className='d-flex gap-4 flex-wrap'>
                                <div className="decmore-offer-card" data-aos="flip-right" data-aos-duration="2000">
                                    <span>Design</span>
                                    <p className='text-center'>Decmore is a designer and manufacturer of high-end decorative surfaces and components for wall panelling based in India and exporting it to all corners of the globe.</p>
                                </div>
                                <div className="decmore-offer-card" data-aos="flip-right" data-aos-duration="2000">
                                    <span>Quality</span>
                                    <p className='text-center'>We offer a variety of colours, textures, size and finish that allows you total freedom of expression during the creative process.</p>
                                </div>
                                <div className="decmore-offer-card" data-aos="flip-right" data-aos-duration="2000">
                                    <span>Durability</span>
                                    <p className='text-center'>The decorative panels we manufacture are not just stylish but also environment-friendly. Panel are made from moisture-resistance engineered wood.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='my-5 py-5'>

                <div className="text-center">
                    <span className='text-dark fw-normal ff-quicksand d-block mt-5 mb-3' style={{ fontSize: '3.5rem' }}>Our Products</span>
                </div>

                <div className="container-fluid d-flex flex-wrap gap-5 justify-content-center">
                    {
                        decmoreProducts.map((ele) => {
                            return <DecmoreProduct title={ele.productName} img={ele.img} key={`decmoreProducts-${ele.id}`} />
                        })
                    }
                </div>
            </section>
        </>
    )
}

export default Decmore
