import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/SideComponent.scss';

const About = () => {
    return (
        <>
            <section className="navFiller d-flex align-items-center justify-content-center flex-column" id='testing'>
                <div className='row mx-1 my-5 py-5'>
                    <span>&nbsp;</span>
                </div>

                <div className="row mx-1 my-5 px-5 py-4 justify-content-center flex-column">
                    <span className="fs-custom-big text-center">Our Journey</span>

                    <div className='d-flex align-items-center justify-content-center gap-3 w-100 my-3'>
                        <Link to="/" className='text-decoration-none'><span className='fs-4 fw-bold text-white'>Home</span></Link>
                        <i className="fa-solid fa-angle-right text-white"></i>
                        <span className='fs-4 fw-bold text-white'>About</span>
                    </div>
                </div>
            </section>

            <section className="my-5">
                <div className="journey-section-custom-scroll-view">
                    <img src={require('../images/journey.webp')} alt="" />
                </div>
            </section>

            <section className="my-5 container px-4">
                <div className="w-100">
                    <div className="row mb-3 mx-0">
                        <span className='fw-normal text-theam width-fit px-0 border-0' style={{ fontSize: '3rem' }}>
                            Director's Message&nbsp;
                        </span>
                    </div>

                    <div className='w-100 d-flex flex-xxl-row flex-lg-row flex-md-column-reverse flex-column-reverse justify-content-xxl-between justify-content-lg-between justify-content-md-center justify-content-center gap-5'>
                        <div className="">
                            <p className='ff-quicksand text-justify' style={{ fontSize: '160%' }}>
                                I take this opportunity to express our heartfelt gratitude to our valued clients and customers. Your continued patronage and confidence in our products and services inspire us to provide the best possible value for your hard-earned money. Our primary objective is to meet the ever-changing needs of our customers with the most effective and sincere approach.
                                Concept Studio acknowledges the importance of both time and money for our clients. We are dedicated to working diligently to fulfil your requirements efficiently. On one hand, we are flexible and can customize our product offerings according to your needs. On the other hand, we have a zero-tolerance policy for ensuring both quality and on-time delivery.
                                Our organization is committed to expanding our business and entering new segments where there is potential, as we have envisioned within the nation. We reaffirm our dedication to providing the highest quality services and encourage you to place your trust in us to meet your challenging needs.
                            </p>
                        </div>

                        <div className="col-xxl-5 col-lg-5 col-md-12 col-12">
                            <img src={require('../images/about/temp.jpg')} alt="" style={{ maxHeight: '40rem', width: '100%', objectFit: 'cover', objectPosition: 'center 80%', borderRadius: '5px' }} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About
