import React, { useContext, useState } from 'react'
import GenralContext from '../context/GenralContext'

const TestimonialTR = (props) => {

    const GC = useContext(GenralContext)
    const { host, toDateString } = GC

    const [curr, setCurr] = useState(props.status)
    // eslint-disable-next-line
    const handleSubmit = async (e, id) => {
        if (curr === true) {
            setCurr(false)
            await fetch(`${host}/testimonial/set/false/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'Appliaction/json',
                    'auth-token': localStorage.getItem('auth-token')
                }
            }).then(async (result) => {
                let data = await result.json();
                console.log('debug - working 2');
                console.log(data.data.status);
            }).catch(error => {
                console.error(error);
            })
        } else {
            setCurr(true)
            await fetch(`${host}/testimonial/set/true/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'Appliaction/json',
                    'auth-token': localStorage.getItem('auth-token')
                }
            }).then(async (result) => {
                let data = await result.json();
                console.log('debug - working');
                console.log(data.data.status);
            }).catch(error => {
                console.error(error);
            })
        }
    }

    const handleDeleteTestimonialData = async (id) => {
        await fetch(`${host}/testimonial/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'Application/json',
                'auth-token': localStorage.getItem('auth-token')
            }
        }).then(async (data) => {
            let res = await data.json()
            if (res === 'Testimonial Data Deleted') {
                window.alert('Data deleted')
                window.location.reload()
            } else {
                window.alert('Somthing went wrong with servers')
                console.error(res);
            }
        })
    }

    return (
        <>
            <tr>
                <td>{toDateString(props.date)}</td>
                <td>{props.name}</td>
                <td>{props.degi}</td>
                <td>{props.rating}</td>
                <td>{props.mess}</td>
                <td><a href={`mailto:${props.email}`}>{props.email}</a></td>
                {/* <td>
                    <form>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" onClick={(e) => handleSubmit(e, props.id)} defaultChecked={curr} />
                        </div>
                    </form>
                </td> */}
                <td>
                    <button type="button" className="d-flex justify-content-center align-items-start btn-reset w-100" onClick={() => { handleDeleteTestimonialData(props.id) }}>
                        <i className="fa-solid fa-trash text-theam cursor-pointer"></i>
                    </button>
                </td>
            </tr>
        </>
    )
}

export default TestimonialTR
